import React, { useState } from "react";
import {
  DropDownStyled,
  NikeDesignSystemProvider,
  PullQuote,
} from "@nike/nike-design-system-components";
import { Button } from "@nike/epic-react-ui";
import Header from "../components/Header";
import data from "../config/adGrps.json";
import { useNavigate } from 'react-router-dom';
import config from "../config/config.js";

function HomePage(props) {
  const [dcValue, setDcValue] = useState("");
  const [dcLabel, setDcLabel] = useState("");
  const [validDc, setValidDc] = useState(false);
  const navigate = useNavigate();

  const handleDcValue = (dc) => {
    setDcLabel(dc.target.selectedOptions[0].label);
    setDcValue(dc.target.value);
    if (dc.target.value !== "") setValidDc(true);
  };

  const handleOnSubmit = async () => {
    var env = config.isProd ? "prod" : "non-prod";
    var dbAdGrps = data.adGrps
        .filter((envAdGrps) => {
          return envAdGrps.env.startsWith(env);
        })[0]
        .dcS.filter((grp) => {
          return grp.locationCode.startsWith(dcValue);
        })[0].grps;

    navigate('/home',
        {state: { dc: dcValue, adGroups: dbAdGrps, dcLabel: dcLabel }},
    );
  };

  return (
    <div>
      <Header
        handleLogout={props.handleLogout}
        displayLogout={props.displayLogout}
      />
      <div id="homePage"
           className="eds-flex eds-flex--direction-column eds-flex--justify-content-center eds-gap--8 container-home">
        <NikeDesignSystemProvider>
          <PullQuote text="Welcome to the Node Configuration,Please select the DC sites to proceed further." />
          <DropDownStyled
            name="dcSelect"
            id="dcSelect"
            value={dcValue}
            requiredIndicator="*"
            placeholder="DC"
            error={false}
            autoComplete="off"
            disabled={false}
            supportText="This dropdown will have the list of all the sites using MA Active"
            options={[
              // { label: "1065 - Cortez", value: "1065" },
              // { label: "1067 - Windrunner", value: "1067" },
              { label: "1093 - Impact", value: "1093" },
            ]}
            errorMessage="Error message"
            onChange={(event) => handleDcValue(event)}
            ariaLive="polite"
          />

          <Button
            className="me-1 ms-1"
            large
            type="NikeApp"
            test-id="save-button"
            disabled={!validDc}
            disabledTip={{
              message: "DC Site is a Mandatory field",
              position: "right",
              size: "medium",
            }}
            onClick={() => handleOnSubmit()}
            theme={{
              primary: validDc ? "#000000" : "rgba(215,250,215,0.62)",
              secondary: validDc ? "#ffffff" : "rgba(165,165,165,0.62)",
            }}
          >
            Continue
          </Button>
        </NikeDesignSystemProvider>
      </div>
    </div>
  );
}

export default HomePage;
