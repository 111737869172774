export const GET_SPUR_DETAILS = "GET_SPUR_DETAILS";
export const GET_SPUR_DETAILS_FAILED = "GET_SPUR_DETAILS_FAILED";

export function getSpurDetails(spurDetailList) {
  return {
    type: GET_SPUR_DETAILS,
    spurDetailList,
  };
}

export function getSpurDetailsFailed(spurDetailList) {
  return {
    type: GET_SPUR_DETAILS_FAILED,
    spurDetailList,
  };
}
