import axios from "axios";
import http from "../utilities/http";
import { searchSuccess } from "../redux/actions/spclInstrcnsActions";
import {
  getCustomers,
  searchFailed,
} from "../redux/actions/hashCustomerActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCarrierHubs,
  searchFailedCarrierHubs,
} from "../redux/actions/carrierHubActions";
import {searchLocationMgmtFailed, searchLocationMgmtSuccess} from "../redux/actions/locationManagemetActions";
import {
  getSpurDetails,
  getSpurDetailsFailed
} from "../redux/actions/spurSummaryActions";
import {getSpurDetailsTotal, getSpurDetailsTotalFailed} from "../redux/actions/spurSummaryTotalActions";
import {getSpurDetailsMixed, getSpurDetailsMixedFailed} from "../redux/actions/spurSummaryMixedActions";
import {getLoadingGroupDetails, getLoadingGroupDetailsFailed} from "../redux/actions/loadingGroupActions";

const baseService = {
  getMAWM,
  fetchCustomer,
  post,
  get,
  delete: _delete,
  exportAllData,
  listCustomers,
  listCarrierHubs,
  fetchLocationMgmts,
  fetchSpurFilters,
  fetchSpurDetails,
  fetchSpurDetailsMixed,
  fetchLoadingGroupDetails
};

function getMAWM(url, headers) {
  return (dispatch) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(searchSuccess(res.data.styles));
        return res;
      })
      .catch((error) => {
        //dispatch(searchFailed(error));
      });
  };
}

function listCustomers(url, headers) {
  return (dispatch) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(getCustomers(res.data.customers));
        return res;
      })
      .catch((error) => {
        dispatch(searchFailed([]));
        toast.error("No records Exist");
      });
  };
}

function listCarrierHubs(url, headers) {
  return (dispatch) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(getCarrierHubs(res.data.carrierHubs));
        return res;
      })
      .catch((error) => {
        dispatch(searchFailedCarrierHubs([]));
        toast.error("No records Exist");
      });
  };
}

function fetchLocationMgmts(url, headers) {
  return (dispatch) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(searchLocationMgmtSuccess(res.data.requests));
        return res;
      })
      .catch((error) => {
        dispatch(searchLocationMgmtFailed([]));
        toast.error("No records Exist");
      });
  };
}

function fetchCustomer(url, headers) {
  return (dispatch) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        var result = [];
        result.push(res.data.customer);
        dispatch(getCustomers(result));

        return res;
      })
      .catch((error) => {
        dispatch(searchFailed([]));
        toast.error("No records Exist");
      });
  };
}

function exportAllData(url, headers) {
  return axios
    .get(url, {
      headers: headers,
      responseType: "blob",
    })
    .then(handleResponse)
    .catch(error);
}

function fetchSpurFilters(url, headers) {
  return axios
      .get(url, {
        headers,
      })
      .then(handleResponse)
      .catch(error);
}

function fetchSpurDetails(url, headers) {
  return (dispatch) => {
    axios
        .get(url, {
          headers,
        })
        .then((res) => {
          if (res.error) {
            throw res.error;
          }
          dispatch(getSpurDetails(res.data.spurDetailList));
          dispatch(getSpurDetailsTotal(res.data.spurDetailListTotal));
          return res;
        })
        .catch((error) => {
          dispatch(getSpurDetailsFailed([]));
          dispatch(getSpurDetailsTotalFailed([]));
          toast.error("No records Exist");
        });
  };
}

function fetchSpurDetailsMixed(url, headers) {
  return (dispatch) => {
    axios
        .get(url, {
          headers,
        })
        .then((res) => {
          if (res.error) {
            throw res.error;
          }
          dispatch(getSpurDetailsMixed(res.data.spurDetailList));
          return res;
        })
        .catch((error) => {
          dispatch(getSpurDetailsMixedFailed([]));
          toast.error("No records Exist");
        });
  };
}

function fetchLoadingGroupDetails(url, headers) {
  return (dispatch) => {
    axios
        .get(url, {
          headers,
        })
        .then((res) => {
          if (res.error) {
            throw res.error;
          }
          dispatch(getLoadingGroupDetails(res.data.loadingGroupDetailList));
          return res;
        })
        .catch((error) => {
          dispatch(getLoadingGroupDetailsFailed([]));
          // if status code is 409, then show the error message as warning
          if (error.response.status === 409) {
            toast.warn(error.response.data.message);
          } else {
            toast.error("No records Exist");
          }
        });
  };
}

function post(url, headers, body) {
  return axios
    .post(url, body, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function get(url, headers) {
  return axios
    .get(url, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function _delete(url, headers, body) {
  return axios
    .delete(url, {
      headers: headers,
      data: body,
    })
    .then(handleResponse)
    .catch(error);
}

function handleResponse(response) {
  if (!http.isValidHttpCode(response.status)) {
    return Promise.reject(response);
  }
  return response;
}

function error(error) {
  let errorResponse = "";
  if (axios.isCancel(error)) {
    errorResponse = error.message;
  } else {
    errorResponse = error.response
      ? error.response
      : { response: { status: 500 } };
  }
  return Promise.reject(errorResponse);
}

export default baseService;
