import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function loadAllCarrierHubRecords(destination_location_code) {
    let destinationCode = `filters=destination_location_code(${destination_location_code})`;
    let url = API_INTERFACE_URI.GET_ALL_CARRIER_HUBS;
    if (destination_location_code !== undefined)
        url = url + "?" + destinationCode;
    return baseService.listCarrierHubs(url, globalHeaders);
}

function saveCarrierHub(payload) {
    return baseService.post(
        API_INTERFACE_URI.SAVE_CARRIER_HUB,
        globalHeaders,
        payload
    );
}

function deleteCarrierHubs(carrierHubs, destination_location_code) {
    let carrierHubParams = `filters=carrierHubId(${carrierHubs})`;
    let url = API_INTERFACE_URI.DELETE_CARRIER_HUBS;
    if (destination_location_code !== "") {
        carrierHubParams = carrierHubParams.concat(
            `&filters=destination_location_code(${destination_location_code})`
        );
    }
    if (destination_location_code !== undefined) url = url + "?" + carrierHubParams;
    return baseService.delete(url, globalHeaders);
}

export const CarrierHubService = {
    loadAllCarrierHubRecords,
    saveCarrierHub,
    deleteCarrierHubs,
};
