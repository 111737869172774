import React, { useState, useEffect } from "react";
import {Button, PageSpinner} from "@nike/epic-react-ui";
import { Row, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import {
  NikeDesignSystemProvider,
  ButtonStyled,
} from "@nike/nike-design-system-components";
import {toast} from "react-toastify";
import {LoadingGroupService} from "../../services/service.loadingGroup";

function GrpActionButton(props) {
  const [showPageSpinner, setshowPageSpinner] = useState(false);
  const [validAddUpdate, setValidAddUpdate] = useState(true);
  const [show, setShow] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAlertModalClose = () => setShowAlertModal(false);

  const handleConfirm = (action) => {
    let message = "Loading Group completed successfully";
    handleUpdate(action, message)
    handleClose();
  };

  const handleUpdate = async (action, message) => {
    let payload = {
      loading_group: [props.loadingGroup],
      ui_loading_group: [],
      action: action,
      spur: "",
      new_spur: "",
      destination_location_code: props.dcValue,
      modified_by: props.loggedInUser,
    };
    setshowPageSpinner(true);
    await LoadingGroupService.updateLoadingGroups(payload)
        .then(response => {
          if (response.status === 200) {
            toast.success(message);
          } else if (response.status === 207) {
            toast.error(response.data.message)
          }
          props.loadLoadingGroupDetails(props.dcValue, props.loadingGroupFilter, props.carrier, props.cutOffStart, props.cutOffEnd);
          props.loadSpurDetails(props.dcValue, props.spur, props.spurType, props.status, props.shipmentId);
        })
        .catch((response) => {
          if (response !== undefined && response.data !== undefined)
            toast.error(response.data.message);
          props.onDeselectRows();
        });
    setshowPageSpinner(false);
  };

  useEffect(() => {
    async function validateData() {
      if (validAddUpdate) {
        setValidAddUpdate(true);
      } else {
        setValidAddUpdate(false);
      }
    }
    validateData();
  }, [validAddUpdate]);

  return (
    <div className=" row ">
      <PageSpinner show={showPageSpinner} />
      <NikeDesignSystemProvider>
        <Form.Group as={Row} className="justify-content-center">
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper"
            data-testid="complete-button"
            type="button"
            onClick={() => handleShow()}
            disabled={!(props.selectedRows.length===1 && props.selectedRows[0].final_plan==='Y' && props.selectedRows[0].remaining_olpns==="0")}
          >
            {"Complete"}
          </ButtonStyled>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>Please confirm COMPLETE of Loading Group {props.loadingGroup}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirm("Complete")}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAlertModal} onHide={handleAlertModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a record(s)</Modal.Body>
          </Modal>
        </Form.Group>
      </NikeDesignSystemProvider>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    spurDetailList: state.spurDetailList,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSpurDetails: LoadingGroupService.loadSpurDetails,
      loadLoadingGroupDetails: LoadingGroupService.loadLoadingGroupDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GrpActionButton);
export { GrpActionButton };
