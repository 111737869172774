export const GET_SPUR_DETAILS_TOTAL = "GET_SPUR_DETAILS_TOTAL";
export const GET_SPUR_DETAILS_TOTAL_FAILED = "GET_SPUR_DETAILS_TOTAL_FAILED";


export function getSpurDetailsTotal(spurDetailListTotal) {
  return {
    type: GET_SPUR_DETAILS_TOTAL,
    spurDetailListTotal,
  };
}

export function getSpurDetailsTotalFailed(spurDetailListTotal) {
  return {
    type: GET_SPUR_DETAILS_TOTAL_FAILED,
    spurDetailListTotal,
  };
}
