import React, { useState, useEffect } from "react";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import {
  NikeDesignSystemProvider,
  ButtonStyled,
} from "@nike/nike-design-system-components";
import {toast} from "react-toastify";
import {LoadingGroupService} from "../../services/service.loadingGroup";

const LOOSELOAD_SPUR_TYPE = "LooseLoad";
const DUMPHOLDER_SPUR_TYPE = "DumpHolder";
const PALLETIZE_SPUR_TYPE = "Palletize";

const CARRIER_LOAD_TYPE_CODE_PARCEL = "PARCEL";

function Assign(props) {
  const [validAddUpdate, setValidAddUpdate] = useState(true);
  const [assignMessage, setAssignMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [validCriteria, setValidCriteria] = useState(false);
  const [showPageSpinner, setshowPageSpinner] = useState(false);

  const handleClose = () => setShow(false);

  function validate() {
    if (props.groupSelectedRows.length > 1)
      setAssignMessage("Please confirm ASSIGN of Loading Groups to Spur "+props.spurSelectedRows[0].spur);
    else {
      setAssignMessage("Please confirm ASSIGN of Loading Group "+props.groupSelectedRows[0].loading_group_id+" to Spur "+props.spurSelectedRows[0].spur);
    }
  }

  const handleShow = () => {
    validate();
    setShow(true);
  }

  const handleAlertModalClose = () => setShowAlertModal(false);

  const handleConfirm = async () => {
    let payload = {
      loading_group: props.groupSelectedRows.map(loadingGroupRecord => loadingGroupRecord.loading_group_id),
      ui_loading_group: props.spurSelectedRows[0].loading_group === "" ? [] : props.spurSelectedRows.map(spurRecord => spurRecord.loading_group === "" ? null : spurRecord.loading_group),
      spur: props.spurSelectedRows[0].spur,
      action: "Assign",
      destination_location_code: props.dcValue,
      modified_by: props.loggedInUser,
      enable_multi_parcel_live_spurs: props.enableMultiParcelLiveSpurs
    };
    handleClose();
    setshowPageSpinner(true);
    await LoadingGroupService.updateLoadingGroups(payload)
        .then(response => {
          if (response.status === 200) {
            toast.success("Loading Groups assigned successfully");
          } else if (response.status === 207) {
            toast.error(response.data.message)
          }
          props.loadLoadingGroupDetails(props.dcValue, props.loadingGroup, props.carrier, props.cutOffStart, props.cutOffEnd);
          props.loadSpurDetails(props.dcValue, props.spur, props.spurType, props.status, props.shipmentId);
        })
        .catch((response) => {
          if (response !== undefined && response.data !== undefined)
            toast.error(response.data.message);
        });
    setshowPageSpinner(false);
    setValidCriteria(false);
    props.onGroupSelectRow([]);
    props.onSpurSelectRow([]);
  };

  useEffect(() => {
    async function validateData() {
      if (validAddUpdate) {
        setValidAddUpdate(true);
      } else {
        setValidAddUpdate(false);
      }
    }
    validateData();
  }, [validAddUpdate]);

  function isEnableMultiParcelLiveSpursConditionsMet() {
      if (props.enableMultiParcelLiveSpurs && props.groupSelectedRows.length >= 1 && props.spurSelectedRows[0].spur_type === LOOSELOAD_SPUR_TYPE) {
        for (var group of props.groupSelectedRows) {
          if (group.carrierload_type_code === undefined || group.carrierload_type_code !== CARRIER_LOAD_TYPE_CODE_PARCEL)
            return false;
        }

        if (props.spurSelectedRows[0].loading_group !== "") {
          for (var carrierload_type_code of props.spurSelectedRows[0].carrierload_type_codes) {
            if (carrierload_type_code === " " || carrierload_type_code !== CARRIER_LOAD_TYPE_CODE_PARCEL)
              return false;
          }
        }

        const allGroupCarriersEqual = arr => arr.every(group => group.carrier_id === arr[0].carrier_id)

        const allSpurCarriersEqual = arr => {
          if (props.spurSelectedRows[0].loading_group === "")
            return true;
          else if (props.spurSelectedRows[0].carrier_id_hidden === undefined){
            return true;
          }
          else {
           return arr.every(carrier => carrier === arr[0])
          }
        }

        if (allGroupCarriersEqual(props.groupSelectedRows)
            && (allSpurCarriersEqual(props.spurSelectedRows[0].carrier_id_hidden))
            && (props.spurSelectedRows[0].loading_group === "" || props.spurSelectedRows[0].carrier_id_hidden === undefined ||
                (props.groupSelectedRows[0].carrier_id === undefined && props.spurSelectedRows[0].carrier_id_hidden[0] === " ") ||
                props.groupSelectedRows[0].carrier_id === props.spurSelectedRows[0].carrier_id_hidden[0])) {
          return true;
        }
      }
      return false;
  }

  useEffect(() => {
    if (props.groupSelectedRows.length>0 && props.spurSelectedRows.length === 1) {
      if (props.spurSelectedRows[0].spur_type === PALLETIZE_SPUR_TYPE || props.spurSelectedRows[0].spur_type === DUMPHOLDER_SPUR_TYPE || isEnableMultiParcelLiveSpursConditionsMet()) {
        setValidCriteria(true);
      } else if (props.groupSelectedRows.length === 1 && props.groupSelectedRows[0].pltz === 'L' && props.spurSelectedRows[0].spur_type === LOOSELOAD_SPUR_TYPE && props.spurSelectedRows[0].loading_group==="") {
        setValidCriteria(true);
      } else {
        setValidCriteria(false);
      }
    } else {
      setValidCriteria(false);
    }
  }, [props.groupSelectedRows,props.spurSelectedRows]);

  return (
    <div className="text-center wrapper">
      <NikeDesignSystemProvider>
        <div className="mb-3">
          <PageSpinner show={showPageSpinner} />
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper"
            data-testid="assign-button"
            type="button"
            size="medium"
            onClick={() => handleShow()}
            disabled={!validCriteria}
          >
            {"Assign >>"}
          </ButtonStyled>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body> {assignMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleConfirm}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAlertModal} onHide={handleAlertModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a record(s)</Modal.Body>
          </Modal>
        </div>
      </NikeDesignSystemProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    spurDetailList: state.spurDetailList,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSpurDetails: LoadingGroupService.loadSpurDetails,
      loadLoadingGroupDetails: LoadingGroupService.loadLoadingGroupDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Assign);
export { Assign };
