const dataStore = {
  instrcns: [],
  customers: [],
  carrierHubs: [],
  launch: [],
  locationManagement: [],
  spurDetailList: [],
  spurDetailListTotal: [],
  spurDetailMixedList: [],
  loadingGroupList: [],
};
export default dataStore;
