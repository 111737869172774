import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";
import moment from "moment";

function updateSpecialInstructions(payload) {
  return baseService.post(
    API_INTERFACE_URI.SPCL_INSTRCNS_PATH,
    globalHeaders,
    payload
  );
}

function deleteSpecialInstructions(payload) {
  return baseService.delete(
    API_INTERFACE_URI.DEL_SPCL_INSTRCNS_PATH,
    globalHeaders,
    payload
  );
}

//get with redux
function getStyle(itemStyle, itemColor) {
  let styleParams = `filters=style(${itemStyle})`;
  if (itemColor !== "") {
    styleParams = styleParams.concat(`&filters=color(${itemColor})`);
  }
  let url = API_INTERFACE_URI.SEARCH_STYLE_PATH;
  if (styleParams !== undefined) url = url + "?" + styleParams;
  return baseService.getMAWM(url, globalHeaders);
}

function loadAllLocalRecords() {
  return baseService.getMAWM(
    API_INTERFACE_URI.LOCAL_DB_SPCL_INSTRNS,
    globalHeaders
  );
}

function searchInstructionService(itemStyle, itemColor, fromDate, toDate, user) {
  let paramAdded = false;
  let instructionParams = ``;
  let url = API_INTERFACE_URI.SEARCH_INSTRUCTIONS;
  if (itemStyle !== "") {
    instructionParams = instructionParams.concat(
      `&filters=style(${itemStyle})`
    );
    paramAdded = true;
  }
  if (itemColor !== "") {
    if (paramAdded) {
      instructionParams = instructionParams.concat(
        `&filters=color(${itemColor})`
      );
    } else {
      instructionParams = instructionParams.concat(
        `filters=color(${itemColor})`
      );
      paramAdded = true;
    }
  }
  if ((null != toDate && toDate !== "") && (null != fromDate && fromDate !== "")){
    const startDate = moment(fromDate).format("MM/DD/YYYY");
    const endDate = moment(toDate).format("MM/DD/YYYY");

    if (paramAdded) {
      instructionParams = instructionParams.concat(
        `&filters=dateFrom(${startDate})&filters=dateTo(${endDate})`
      );
    } else {
      instructionParams = instructionParams.concat(
        `filters=dateFrom(${startDate})&filters=dateTo(${endDate})`
      );
      paramAdded = true;
    }
  }
  if (user !== "") {
    if (paramAdded) {
      instructionParams = instructionParams.concat(`&filters=user(${user})`);
    } else {
      instructionParams = instructionParams.concat(`filters=user(${user})`);
    }
  }
  if (instructionParams !== undefined) url = url + "?" + instructionParams;
  return baseService.getMAWM(url, globalHeaders);
}

function exportAll() {
  let url = API_INTERFACE_URI.EXPORT_ALL_PATH;
  return baseService.exportAllData(url, globalHeaders);
}

export const SpecialInstructionService = {
  updateSpecialInstructions,
  deleteSpecialInstructions,
  getStyle,
  searchInstructionService,
  exportAll,
  loadAllLocalRecords,
};
