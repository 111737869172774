import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {LoadingGroupService} from "../../services/service.loadingGroup";
import moment from "moment";

class SpurPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      isSelected: false,
      isHeaderSelected: false,
      api: null,
      containerStyle: { width: "100%", height: "100%" },
      gridStyle: { height: "100%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        autoHeight: true,
      },
    };
  }

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  render() {

    const numberSort = (num1: number, num2: number) => {
      return num1 - num2;
    };

    const dateTimeSort = (dateTime1: string, dateTime2: string) => {
      if (dateTime1 === "")
        return -1;
      if(dateTime2 === "")
        return 1;
      const timestamp1 = new Date(dateTime1);
      const timestamp2 = new Date(dateTime2);
      return timestamp1 - timestamp2;
    };

    const DateTimeRenderer = (p) => {
      if (p.value === "")
        return " ";
      const dateTime = new Date(p.value);
      return moment(dateTime).format("MMMM D, yyyy") + '\n' + moment(dateTime).format("hh:mm:ss A");
    };

    const RadioButtonRenderer = (params) => {
      return (
          <input
              type="radio"
              name="radioSelection"
              checked={params.node.selected}
              onChange={() => params.node.setSelected(true)}
          />
      );
    };

    const columnDefs = [
      {
        cellRenderer: RadioButtonRenderer,
        field: "selectAll",
        headerName: "",
        width: 70,
      },
      {
        headerName: "Spur",
        field: "spur",
        width: 150,
      },
      {
        headerName: "Spur Type",
        field: "spur_type",
        width: 150,
      },
      {
        headerName: " Loading Group",
        field: "loading_group",
        width: 200,
      },
      {
        headerName: "Carrier",
        field: "carrier_id",
        width: 98,
        hide: !this.props.displayAllLGAttr
      },
      {
        headerName: "Service",
        field: "service_level_code",
        width: 101,
        hide: !this.props.displayServiceLevelCode
      },
      {
        headerName: "Shipment",
        field: "shipment_id",
        autoHeight: true,
        width: 149,
        hide: !this.props.displayAllLGAttr
      },
      {
        headerName: "GTP",
        field: "gtp_datetime",
        width: 155,
        hide: !this.props.displayGTP,
        cellStyle: { 'white-space': 'pre' },
        comparator: dateTimeSort,
        cellRenderer: DateTimeRenderer
      },
      {
        headerName: "Internal Cut-off",
        field: "cutoff",
        width: 155,
        comparator: dateTimeSort,
        cellRenderer: DateTimeRenderer,
        cellStyle: { 'white-space': 'pre' }
      },
      {
        headerName: "LG Type",
        field: "lg_type",
        width: 110,
      },
      {
        headerName: "LG Status",
        field: "lg_status",
        width: 150,
      },
      { headerName: "oLPNs",
        field: "olpns",
        width: 150,
        comparator: numberSort
      },
      { headerName: "Rem oLPNs",
        field: "remaining_olpns",
        width: 150,
        comparator: numberSort
      },
      {
        headerName: "Final",
        field: "final_plan",
        width: 100,
      },
    ];

    const onGridReady = (params) => {
      this.setState({ api: params.api });
      this.props.loadSpurDetailsMixed(this.props.dcValue, this.props.mixedSpur, "Mixed");
    };

    const onRowSelectionChanged = () => {
      const selectedRows = this.state.api.getSelectedRows();
      this.setState({ selectedRow: selectedRows.length > 0 ? selectedRows[0] : null });
      this.props.onPopupRowSelectionChanged(this.state.api.getSelectedRows(), this.state.api.getSelectedRows()[0].loading_group, this.state.api.getSelectedRows()[0].spur);
    };

    return (
      <div style={this.state.containerStyle}>
        <div style={this.state.gridStyle} className="ag-theme-alpine mb-3">
          <AgGridReact
            rowData={this.props.spurDetailMixedList}
            columnDefs={columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"single"}
            pagination={true}
            enableCellTextSelection={true}
            suppressHorizontalScroll={false}
            paginationPageSize={10}
            onGridReady={onGridReady}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSpurDetailsMixed: LoadingGroupService.loadSpurDetailsMixed,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    spurDetailMixedList: state.spurDetailMixedList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpurPopUp);

export { SpurPopUp };
