
export const GET_SPUR_DETAILS_MIXED = "GET_SPUR_DETAILS_MIXED";
export const GET_SPUR_DETAILS_MIXED_FAILED = "GET_SPUR_DETAILS_MIXED_FAILED";

export function getSpurDetailsMixed(spurDetailMixedList) {
  return {
    type: GET_SPUR_DETAILS_MIXED,
    spurDetailMixedList,
  };
}

export function getSpurDetailsMixedFailed(spurDetailMixedList) {
  return {
    type: GET_SPUR_DETAILS_MIXED_FAILED,
    spurDetailMixedList,
  };
}
