import React, { useState, useEffect } from "react";
import {Button, PageSpinner} from "@nike/epic-react-ui";
import { Row, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import {
  NikeDesignSystemProvider,
  ButtonStyled, TextStyled, Typeahead,
} from "@nike/nike-design-system-components";
import {toast} from "react-toastify";
import {LoadingGroupService} from "../../services/service.loadingGroup";

function SpurActionButton(props) {
  const [showPageSpinner, setshowPageSpinner] = useState(false);
  const [spur, setSpur] = useState("");
  const [validAddUpdate, setValidAddUpdate] = useState(true);
  const [showUnassign, setShowUnassign] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [showComplete, setShowComplete] = useState(false);

  const handleCloseUnassign = () => setShowUnassign(false);
  const handleCloseSuspend= () => setShowSuspend(false);
  const handleCloseReassign= () => {
    setSpur("");
    setShowReassign(false);
  }
  const handleCloseComplete = () => setShowComplete(false);

  const handleShowUnassign = () => setShowUnassign(true);
  const handleShowSuspend = () => setShowSuspend(true);
  const handleShowReassign = () => {
    setShowReassign(true);
  }
  const handleShowComplete= () => setShowComplete(true);

  const handleConfirmUnassign = (action) => {
    let message = "Loading Group unassigned successfully"
    handleCloseUnassign();
    handleUpdate(action, message)
  };

  const handleConfirmSuspend = async (action) => {
    let message = "Loading Group suspended successfully";
    handleCloseSuspend();
    handleUpdate(action, message)

  };

  const handleConfirmReassign = async (action) => {
    let message = "Loading Group reassigned successfully";
    handleCloseReassign();
    handleUpdate(action, message)
  };

  const handleConfirmComplete = async (action) => {
    let message = "Loading Group completed successfully";
    handleCloseComplete();
    handleUpdate(action, message)
  };

  const handleUpdate = async (action, message) => {
    let payload = {
      loading_group: props.selectedRows.map(loadingGroupRecord => loadingGroupRecord.loading_group),
      ui_loading_group: fetchUiLoadingGroup(action),
      spur: props.selectedRows[0].spur,
      new_spur: spur,
      action: action,
      destination_location_code: props.dcValue,
      modified_by: props.loggedInUser,
      enable_multi_parcel_live_spurs: props.enableMultiParcelLiveSpurs
    };
    setshowPageSpinner(true);
    await LoadingGroupService.updateLoadingGroups(payload)
        .then(response => {
          if (response.status === 200) {
            toast.success(message);
          } else if (response.status === 207) {
            toast.error(response.data.message)
          }
          props.loadLoadingGroupDetails(props.dcValue, props.loadingGroupFilter, props.carrier,props.cutOffStart, props.cutOffEnd);
          props.loadSpurDetails(props.dcValue, props.spurFilter, props.spurType, props.status, props.shipmentId);
        })
        .catch((response) => {
          if (response !== undefined && response.data !== undefined)
            toast.error(response.data.message);
        });
    setshowPageSpinner(false);
    props.onGroupSelectRow([]);
    props.onSpurSelectRow([]);
  };

  useEffect(() => {
    async function validateData() {
      if (validAddUpdate) {
        setValidAddUpdate(true);
      } else {
        setValidAddUpdate(false);
      }
    }
    validateData();
  }, [validAddUpdate]);

  function isEnableButton() {
    return !(props.selectedRows.length === 1 && !["Mixed",""].includes(props.selectedRows[0].loading_group));
  }

  function isEnableComplete() {
    return !(props.selectedRows.length === 1 && !["Mixed",""].includes(props.selectedRows[0].loading_group) && props.selectedRows[0].final_plan === 'Y' && props.selectedRows[0].remaining_olpns==="0");
  }

  function fetchUiLoadingGroup(action) {
    if(action === "Reassign") {
      const spurObj = props.spurDetailListTotal.filter(obj => {
        return obj.spur === spur;
      });
      const uiLoadingGroup = spurObj[0].loading_group;
      return uiLoadingGroup === "" ? [] : [uiLoadingGroup];
    } else {
      return props.selectedRows[0].loading_group === "" ? [] : props.selectedRows.map(spurRecord => spurRecord.loading_group === "" ? null : spurRecord.loading_group);
    }
  }

  return (
    <div className=" row mt-1 ps-2 ">
      <PageSpinner show={showPageSpinner} />
      <NikeDesignSystemProvider>
        <Form.Group as={Row} className="justify-content-center">
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper margin-right"
            data-testid="unassign-button"
            type="button"
            onClick={() => handleShowUnassign()}
            disabled={isEnableButton()}
          >
            {"Unassign"}
          </ButtonStyled>
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper margin-right"
            data-testid="suspend-button"
            type="button"
            onClick={() => handleShowSuspend()}
            disabled={isEnableButton()}
          >
            {"Suspend"}
          </ButtonStyled>
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper margin-right"
            data-testid="reassign-button"
            type="button"
            onClick={() => handleShowReassign()}
            disabled={isEnableButton()}
          >
            {"Reassign"}
          </ButtonStyled>
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper"
            data-testid="complete-button"
            type="button"
            onClick={() => handleShowComplete()}
            disabled={isEnableComplete()}
          >
            {"Complete"}
          </ButtonStyled>

          <Modal show={showUnassign} onHide={handleCloseUnassign}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>Please confirm UNASSIGN of Loading Group {props.loadingGroup} from Spur {props.spur}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirmUnassign("Unassign")}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleCloseUnassign}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showSuspend} onHide={handleCloseSuspend}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>Please confirm SUSPEND of Loading Group {props.loadingGroup} from Spur {props.spur}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirmSuspend("Suspend")}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleCloseSuspend}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showReassign} onHide={handleCloseReassign}>
            <Modal.Header closeButton>
              <div className='fw-bold'>Reassign Loading Group {props.loadingGroup}</div>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="ms-0 mt-4 ps-5 w-25"><TextStyled appearance="" className="ms-1">Spur</TextStyled></div>
                <Typeahead
                    id="spur"
                    label=""Spur
                    disabled = {true}
                    controlledValue={spur}
                    className="w-50 mt-3"
                    //noResultsMessage="Node Not Applicable"
                    searchableItems={props.spurList}
                    showListboxWhenEmpty={true}
                    onInputChange={(value) => setSpur(value)}
                    onItemSelect={(value) => setSpur(value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirmReassign("Reassign")}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleCloseReassign}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showComplete} onHide={handleCloseComplete}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>Please confirm COMPLETE of Loading Group {props.loadingGroup} from Spur {props.spur}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirmComplete("Complete")}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleCloseComplete}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Form.Group>
      </NikeDesignSystemProvider>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    spurDetailList: state.spurDetailList,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSpurDetails: LoadingGroupService.loadSpurDetails,
      loadLoadingGroupDetails: LoadingGroupService.loadLoadingGroupDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SpurActionButton);
export { SpurActionButton };
