import initialState from "./initialState";

export default function customersReducer(
  state = initialState.customers,
  action
) {
  switch (action.type) {
    case "GET_CUSTOMERS":
      return action.customers;
    case "SEARCH_FAILED":
      return action.customers;
    default:
      return state;
  }
}
