import React, { useState } from "react";
import { IconButton } from "@nike/epic-react-ui";
import { Form, Col, Row, Modal } from "react-bootstrap";
import "../component.css";
import "rsuite/dist/rsuite.min.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HashCustomerService } from "../../services/service.hashCustomers";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
} from "@nike/nike-design-system-components";

function LocalCustomerSearch(props) {
  const [customer, setCustomer] = useState("");
  const [isValidCriteria, setValidCriteria] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalShow = () => setShowAlertModal(true);
  const handleAlertModalClose = () => setShowAlertModal(false);

  const handleCustomer = (customer) => {
    setCustomer(customer);
    if (customer !== "") setValidCriteria(true);
  };

  const handleOnClick = () => {
    if (customer === "") {
      handleAlertModalShow();
    } else {
      props.loadCustomer(customer, props.dcValue);
    }
  };

  return (
    <Form id="search-form" className={" row "}>
      <NikeDesignSystemProvider>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className={"fw-bold form-label"}>
              Customer List
            </Form.Label>
            <TextFieldStyled
              value={customer}
              type="text"
              requiredIndicator="*"
              placeholder="Customer"
              name="customerField"
              test-id="local-customer-test-id"
              label="Customer"
              maxLength={20}
              onChange={(event) => {
                handleCustomer(event.target.value);
              }}
              ariaLive="polite"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <IconButton
              type="NikeApp"
              test-id="local-search-button"
              iconSize="m"
              onClick={() => handleOnClick()}
              disabled={!isValidCriteria}
              disabledTip={{
                message: "Customer is a Mandatory field",
                position: "right",
                size: "medium",
              }}
              theme={{
                primary: isValidCriteria ? "#000000" : "rgba(215,250,215,0.62)",
                secondary: isValidCriteria
                  ? "#ffffff"
                  : "rgba(165,165,165,0.62)",
              }}
            >
              Local Search
            </IconButton>
          </Form.Group>
        </Row>
        <Modal show={showAlertModal} onHide={handleAlertModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please select a search criteria</Modal.Body>
        </Modal>
      </NikeDesignSystemProvider>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    customers: state.customers,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCustomer: HashCustomerService.getCustomer,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalCustomerSearch);

export { LocalCustomerSearch };
