export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const SEARCH_FAILED = "SEARCH_FAILED";

export function getCustomers(customers) {
  return {
    type: GET_CUSTOMERS,
    customers,
  };
}

export function searchFailed(customers) {
  return {
    type: SEARCH_FAILED,
    customers,
  };
}
