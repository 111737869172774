import initialState from "./initialState";

export default function locationManagementReducer(
  state = initialState.locationManagement,
  action
) {
  switch (action.type) {
    case "SEARCH_LCTN_MGMT_SUCCESS":
      return action.locationManagement;
    case "SEARCH_LCTN_MGMT_FAILED":
      return action.locationManagement;
    default:
      return state;
  }
}
