import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Search from "../components/SpecialInstructions/Search";

import DefaultTable from "../components/SpecialInstructions/DefaultTable";
import LocalDBSearch from "../components/SpecialInstructions/LocalDBSearch";
import SearchTable from "../components/SpecialInstructions/SearchTable";

export class SpecialInstructionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchMAWM: undefined,
      isSearchLocal: true,
      itemStyle: "",
      itemColor: "",
      itemStyleLocal: "",
      itemColorLocal: "",
      toDateLocal: "",
      fromDateLocal: "",
      userLocal: "",
      selectedRows: [],
      api: null,
    };
  }

  onSelectRow = (value) => {
    this.setState({ selectedRows: value });
  };

  onChangeItemColor = (value) => {
    this.setState({ itemColor: value });
    var element = document.getElementById("itemColor");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(element, value);
    var ev2 = new Event("input", { bubbles: true });
    element.dispatchEvent(ev2);
  };

  onChangeSearchMAWM = (value) => {
    this.setState({ isSearchMAWM: value });
  };

  onChangeItemStyleLocal = (value) => {
    this.setState({ itemStyleLocal: value });
  };

  onChangeItemColorLocal = (value) => {
    this.setState({ itemColorLocal: value });
  };

  onChangeToDateLocal = (value) => {
    this.setState({ toDateLocal: value });
  };

  onChangeFromDateLocal = (value) => {
    this.setState({ fromDateLocal: value });
  };

  onChangeUserLocal = (value) => {
    this.setState({ userLocal: value });
  };

  onChangeItemStyle = (value) => {
    this.setState({ itemStyle: value });
    var element = document.getElementById("itemStyle");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;

    nativeInputValueSetter.call(element, value);
    var ev2 = new Event("input", { bubbles: true });
    element.dispatchEvent(ev2);
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row  mt-3 ps-3">
            <div className="col-sm-2 border-end">
              <LocalDBSearch
                isSearchMAWM={this.state.isSearchMAWM}
                isSearchLocal={this.state.isSearchLocal}
                onChangeSearchMAWM={this.onChangeSearchMAWM}
                onChangeItemStyleLocal={this.onChangeItemStyleLocal}
                onChangeItemColorLocal={this.onChangeItemColorLocal}
                onChangeToDateLocal={this.onChangeToDateLocal}
                onChangeFromDateLocal={this.onChangeFromDateLocal}
                onChangeUserLocal={this.onChangeUserLocal}
                itemStyleLocal={this.state.itemStyleLocal}
                itemColorLocal={this.state.itemColorLocal}
                toDateLocal={this.state.toDateLocal}
                fromDateLocal={this.state.fromDateLocal}
                userLocal={this.state.userLocal}
              />
            </div>

            <div className="col-sm-9 px-4">
              <Search
                isSearchMAWM={this.state.isSearchMAWM}
                isSearchLocal={this.state.isSearchLocal}
                onChangeSearchMAWM={this.onChangeSearchMAWM}
                itemStyle={this.state.itemStyle}
                itemColor={this.state.itemColor}
                onChangeItemStyle={this.onChangeItemStyle}
                onChangeItemColor={this.onChangeItemColor}
              />
              {this.state.isSearchMAWM ? (
                <SearchTable
                  isSearchMAWM={this.state.isSearchMAWM}
                  isSearchLocal={this.state.isSearchLocal}
                  onChangeSearchMAWM={this.onChangeSearchMAWM}
                  onSelectRow={this.onSelectRow}
                  selectedRows={this.state.selectedRows}
                  itemStyle={this.state.itemStyle}
                  itemColor={this.state.itemColor}
                  loggedInUser={this.props.loggedInUser}
                />
              ) : (
                <DefaultTable
                  isSearchMAWM={this.state.isSearchMAWM}
                  isSearchLocal={this.state.isSearchLocal}
                  onChangeSearchMAWM={this.onChangeSearchMAWM}
                  itemStyle={this.state.itemStyle}
                  itemColor={this.state.itemColor}
                  onChangeItemStyle={this.onChangeItemStyle}
                  onChangeItemColor={this.onChangeItemColor}
                  itemStyleLocal={this.state.itemStyleLocal}
                  itemColorLocal={this.state.itemColorLocal}
                  toDateLocal={this.state.toDateLocal}
                  fromDateLocal={this.state.fromDateLocal}
                  userLocal={this.state.userLocal}
                  loggedInUser={this.props.loggedInUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SpecialInstructionContainer;
