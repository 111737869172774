import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function loadSpurFilters(destLocCode) {
  let loadingGroupParams = ``;
  let url = API_INTERFACE_URI.LOADING_GROUP_SPUR_FILTERS;
  loadingGroupParams = loadingGroupParams.concat(
    `filters=destLocCode(${destLocCode})`
  );
  if (loadingGroupParams !== undefined) url = url + "?" + loadingGroupParams;
  return baseService.fetchSpurFilters(url, globalHeaders);
}

function loadSpurDetails(destLocCode, spur, spurType, spurStatus, shipmentId) {
  let spurDetailParams = ``;
  let url = API_INTERFACE_URI.LOADING_GROUP_SPUR_DETAILS;
  if (spur !== "") {
    spurDetailParams = spurDetailParams.concat(`&filters=spur(${spur})`);
  }
  if (spurType !== "") {
    spurDetailParams = spurDetailParams.concat(
      `&filters=spurType(${spurType})`
    );
  }
  if (spurStatus !== "") {
    spurDetailParams = spurDetailParams.concat(
      `&filters=status(${spurStatus})`
    );
  }
  spurDetailParams = spurDetailParams.concat(
    `&filters=destLocCode(${destLocCode})`
  );
  if (shipmentId !== "") {
    spurDetailParams = spurDetailParams.concat(
        `&filters=shipmentId(${shipmentId})`
    );
  }

  if (spurDetailParams !== undefined) url = url + "?" + spurDetailParams;
  return baseService.fetchSpurDetails(url, globalHeaders);
}

function loadSpurDetailsMixed(destLocCode, spur, spurStatus) {
  let spurDetailParams = ``;
  let url = API_INTERFACE_URI.LOADING_GROUP_SPUR_DETAILS;
  if (spur !== "") {
    spurDetailParams = spurDetailParams.concat(`&filters=spur(${spur})`);
  }

  if (spurStatus !== "") {
    spurDetailParams = spurDetailParams.concat(
      `&filters=status(${spurStatus})`
    );
  }
  spurDetailParams = spurDetailParams.concat(
    `&filters=destLocCode(${destLocCode})`
  );

  if (spurDetailParams !== undefined) url = url + "?" + spurDetailParams;
  return baseService.fetchSpurDetailsMixed(url, globalHeaders);
}

function loadLoadingGroupDetails(
  destLocCode,
  loadingGroup,
  carrier,
  cutOffStart,
  cutOffEnd
) {
  let loadingGroupDetailParams = ``;
  let url = API_INTERFACE_URI.LOADING_GROUP_DETAILS;
  if (loadingGroup !== "") {
    loadingGroupDetailParams = loadingGroupDetailParams.concat(
      `&filters=loadingGroup(${loadingGroup})`
    );
  }
  if (carrier !== "") {
    loadingGroupDetailParams = loadingGroupDetailParams.concat(
      `&filters=carrier(${carrier})`
    );
  }
  if (cutOffStart !== "") {
    cutOffStart = cutOffStart + ":00";
    loadingGroupDetailParams = loadingGroupDetailParams.concat(
      `&filters=cutOffStart(${cutOffStart})`
    );
  }
  if (cutOffEnd !== "") {
    cutOffEnd = cutOffEnd + ":00";
    loadingGroupDetailParams = loadingGroupDetailParams.concat(
      `&filters=cutOffEnd(${cutOffEnd})`
    );
  }
  loadingGroupDetailParams = loadingGroupDetailParams.concat(
    `&filters=destLocCode(${destLocCode})`
  );

  if (loadingGroupDetailParams !== undefined)
    url = url + "?" + loadingGroupDetailParams;
  return baseService.fetchLoadingGroupDetails(url, globalHeaders);
}

function updateLoadingGroups(payload) {
  return baseService.post(
      API_INTERFACE_URI.LOADING_GROUP_UPDATE,
      globalHeaders,
      payload
  );
}

function rePrintSpur(payload, action) {
  return baseService.post(
    API_INTERFACE_URI.LOADING_GROUP_REPRINT_SPUR + action,
    globalHeaders,
    payload
  );
}

export const LoadingGroupService = {
  loadSpurFilters,
  loadSpurDetails,
  loadSpurDetailsMixed,
  loadLoadingGroupDetails,
  updateLoadingGroups,
  rePrintSpur,
};
