import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import {globalHeaders, setGlobalHeader} from "../utilities/http";

function importLaunchFile(payload, launchType) {
    setGlobalHeader("Content-Type", "multipart/form-data")

    return baseService.post(
        API_INTERFACE_URI.LAUNCH_VALIDATION_IMPORT.replace("{launchType}",launchType),
        globalHeaders,
        payload
    );
}

function exportLaunchFIle(launchType) {
    let url = API_INTERFACE_URI.LAUNCH_VALIDATION_EXPORT.replace("{launchType}",launchType);
    return baseService.exportAllData(url, globalHeaders);
}

export const LaunchValidationService = {
    importLaunchFile,
    exportLaunchFIle
};
