/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { IconButton } from "@nike/epic-react-ui";
import { Button } from "@nike/epic-react-ui";
import { Form, Col, Row, Modal } from "react-bootstrap";
import "../component.css";
import "rsuite/dist/rsuite.min.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LocationMgmtService } from "../../services/service.locationMgmt";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
  DatePickerStyled,
} from "@nike/nike-design-system-components";
import moment from "moment";

function LocationSearch(props) {
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [user, setUser] = useState("");
  const [toDate, setToDate] = useState(moment().format("yyyy-MM-DD"));
  const [hasErrors, setHasErrors] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalClose = () => setShowAlertModal(false);

  useEffect(() => {
    validateDate(toDate, fromDate);
  }, [toDate, fromDate]);

  const validateDate = (toDateValue, fromDateValue) => {
    if (toDateValue < fromDateValue) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  };

  function handleRefresh() {
    setUser("");
    setFromDate(moment().format("yyyy-MM-DD"));
    setToDate(moment().format("yyyy-MM-DD"));
  }

  const handleUser = (userValue) => {
    setUser(userValue);
    props.handleLocalUser(userValue);
  };

  const handleToDate = (toDate) => {
    setToDate(toDate);
    props.handleLocalToDate(toDate);
  };

  const handleFromDate = (fromDate) => {
    setFromDate(fromDate);
    props.handleLocalFromDate(fromDate);
  };

  const handleOnClick = () => {
    props.localMgmtFunction(fromDate, toDate, user, props.dcValue);
    props.onSelectRow([]);
  };

  return (
    <Form id="search-form" className={"mb-2 me-2 mt-2"} onSubmit={e => { e.preventDefault(); }}>
      <NikeDesignSystemProvider>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className={"fw-bold"}>User</Form.Label>
            <TextFieldStyled
              type="text"
              placeholder="User"
              maxLength={40}
              label="User"
              onChange={(event) => handleUser(event.target.value)}
              id="locationUser"
              test-id="location-user"
              ariaLive="polite"
              value={user}
            />
          </Form.Group>
        </Row>

        <Row className="mt-2">
          <Form.Group as={Col}>
            <Form.Label className={"fw-bold"}>Date Range</Form.Label>
            <DatePickerStyled
              onChange={(event) => handleFromDate(event.target.value)}
              test-id="location-from-date"
              label="From"
              error={hasErrors}
              errorMessage="From date should be less than To date"
              max={moment().format("yyyy-MM-DD")}
              value={fromDate}
            />
            <DatePickerStyled
              onChange={(event) => handleToDate(event.target.value)}
              label="To"
              error={hasErrors}
              className="mt-3"
              errorMessage="From date should be less than To date"
              test-id="location-to-date"
              max={moment().format("yyyy-MM-DD")}
              value={toDate}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3 mt-3">
          <Form.Group as={Col}>
            <IconButton
              type="NikeApp"
              test-id="location-search-button"
              iconSize="m"
              onClick={() => handleOnClick()}
            >
              Local Search
            </IconButton>
          </Form.Group>
        </Row>

        <Row className="mb-3 mt-3">
          <Form.Group
            as={Col}
            controlId="formGridItemColor export-button-icon-size"
          >
            <Button
              type="button"
              test-id="refresh-button"
              onClick={() => handleRefresh()}
              className="refresh-button"
            >
              Reset
            </Button>
          </Form.Group>
        </Row>

        <Modal show={showAlertModal} onHide={handleAlertModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please select a search criteria</Modal.Body>
        </Modal>
      </NikeDesignSystemProvider>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    locationManagement: state.locationManagement,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      localMgmtFunction: LocationMgmtService.loadAllLocationMgmtRecords,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearch);

export { LocationSearch };
