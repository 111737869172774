export const GET_CARRIER_HUBS = "GET_CARRIER_HUBS";
export const CARRIER_HUB_SEARCH_FAILED = "CARRIER_HUB_SEARCH_FAILED";

export function getCarrierHubs(carrierHubs) {
    return {
        type: GET_CARRIER_HUBS,
        carrierHubs,
    };
}

export function searchFailedCarrierHubs(carrierHubs) {
    return {
        type: CARRIER_HUB_SEARCH_FAILED,
        carrierHubs,
    };
}
