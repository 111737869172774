import initialState from "./initialState";

export default function spurSummaryReducer(
  state =  initialState.spurDetailMixedList,
  action
) {
  switch (action.type) {
    case "GET_SPUR_DETAILS_MIXED":
      return action.spurDetailMixedList;
    case "GET_SPUR_DETAILS_MIXED_FAILED":
      return action.spurDetailMixedList;
    default:
      return state;
  }
}
