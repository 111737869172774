import "./App.css";
import React, { useEffect, useState } from "react";
import { AegisClient, useAuth } from "@nike/aegis-auth-react";
import config from "../config/config.js";
import { setGlobalHeader } from "../utilities/http";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import Footer from "../components/Footer";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import RoutingPanel from "../components/RoutingPanel";
import HomePage from "../components/HomePage";
const client = new AegisClient({
  qa: !config.isProd,
  ...config.oAuth,
});

function App() {
  const navigate = useNavigate();

  const { loginComplete, isLoggedIn, clearLogin } = useAuth(client);
  const [userToken, setUserToken] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  const [displayLogout, setDisplayLogout] = useState(true);
  const [adGroup, setAdGroup] = useState();

  useEffect(() => {
    async function handleLogin({ accessToken }) {
      setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
      setUserToken(accessToken.accessToken);
      setDisplayLogout(true);
      setLoggedInUser(accessToken.claims.sub);
      setAdGroup(accessToken.claims.groups);
    }

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loginComplete || !userToken) return <PageSpinner show={true} />;
  if (!isLoggedIn) return <LoggedOut />;

  function handleLogout() {
    setGlobalHeader("Authorization", null);
    clearLogin();
    client.logout();
    setDisplayLogout(false);
    navigate("/logout");
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <HomePage
              handleLogout={handleLogout}
              displayLogout={displayLogout}
            />
          }
        />
        <Route
          path="/home"
          element={
            <RoutingPanel
              loggedInUser={loggedInUser}
              adGroup={adGroup}
              handleLogout={handleLogout}
              displayLogout={displayLogout}
            />
          }
        />
        <Route
            path="/logout"
            element={LoggedOut()}
        />
      </Routes>

      <Footer />
    </div>
  );
}

function LoggedOut() {
  return (
    <div style={{ margin: "50px" }}>
      <div style={{ marginBottom: "20px" }}>
        <span className="epic-body-text-1 table-label">
          You have been logged out from application.
        </span>
        <p>
          <span className="epic-body-text-1 table-label">
            Close the browser to log out from Okta session.
          </span>
        </p>
      </div>
      <Button onClick={(event) => (window.location.href = "/")}>Login</Button>
    </div>
  );
}

export default App;
