import initialState from "./initialState";

export default function spurSummaryReducer(
  state = initialState.spurDetailList,
  action
) {
  switch (action.type) {
    case "GET_SPUR_DETAILS":
      return action.spurDetailList;
    case "GET_SPUR_DETAILS_FAILED":
      return action.spurDetailList;
    default:
      return state;
  }
}