import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import React from "react";
import { bindActionCreators } from "redux";
import { LocationMgmtService } from "../../services/service.locationMgmt";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import fileDownload from "js-file-download";
import {
  Input,
  NikeDesignSystemProvider,
} from "@nike/nike-design-system-components";
import { toast } from "react-toastify";
import moment from "moment";

class LocationSearchTable extends React.Component {
  constructor(props) {
    super(props);

    const numberSort = (num1: number, num2: number) => {
      return num1 - num2;
    };

    const dateTimeSort = (dateTime1: string, dateTime2: string) => {
      const timestamp1 = new Date(dateTime1);
      const timestamp2 = new Date(dateTime2);
      return timestamp1 - timestamp2;
    };

    this.state = {
      showSpinner: false,
      rowData: [],
      isHeaderSelected: false,
      api: null,
      columnDefs: [
        {
          checkboxSelection: true,
          headerCheckboxSelection: false,
          field: "selectAll",
          headerName: "",
          width: 50,
          autoHeight: true,
        },
        {
          headerName: "File Name",
          field: "fileName",
          width: 350,
          autoHeight: true,
        },
        {
          headerName: "Row count",
          field: "rowCount",
          width: 120,
          autoHeight: true,
          comparator: numberSort
        },
        {
          headerName: "Item Count",
          field: "itemCount",
          autoHeight: true,
          width: 130,
          comparator: numberSort
        },
        {
          headerName: "Last updated",
          field: "modifiedOn",
          width: 250,
          autoHeight: true,
          comparator: dateTimeSort
        },
        {
          headerName: "User Email",
          field: "user",
          width: 300,
          autoHeight: true,
        },
        {
          headerName: "Status",
          field: "status",
          width: 250,
          autoHeight: true,
        },
      ],
      containerStyle: { width: "100%", height: "110%" },
      gridStyle: { height: "100%", width: "110%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: false,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
      },
    };
  }


  importLocationMgmt = async (e) => {
    this.setState({ showSpinner: true });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    await LocationMgmtService.importLocationMgmtFile(
      this.props.loggedInUser,
      this.props.dcValue,
      formData
    )
      .then(() => {
        this.setState({ showSpinner: false });
        toast.success("File saved successfully");
        this.props.searchLocationFunction(
          this.props.localFromDate,
          this.props.localToDate,
          "",
          this.props.dcValue
        );
      })
      .catch((response) => {
        this.setState({ showSpinner: false });
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  };

  sendToMAWM = async (e) => {
    this.setState({ showSpinner: true });
    await LocationMgmtService.sendToMAWM(
      this.props.selectedRows,
      this.props.loggedInUser
    )
      .then(() => {
        this.setState({ showSpinner: false });
        toast.success("File sent to MAWM successfully");
        this.props.onRowSelection(false);
        this.props.searchLocationFunction(
          this.props.localFromDate,
          this.props.localToDate,
          "",
          this.props.dcValue
        );
        this.props.onSelectRow([]);
      })
      .catch((response) => {
        this.setState({ showSpinner: false });
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
        this.props.onRowSelection(false);
      });
  };

  exportLocFile = async (e) => {
    this.setState({ showSpinner: true });
    let fileName = this.props.selectedRows.map((row) => row.fileName);
    await LocationMgmtService.downloadLocFile(fileName, this.props.dcValue)
      .then((res) => {
        fileDownload(res.data, fileName, "text/csv");
        this.setState({ showSpinner: false });
        toast.success("File downloaded successfully");
        this.props.onRowSelection(false);
        this.props.searchLocationFunction(
          this.props.localFromDate,
          this.props.localToDate,
          "",
          this.props.dcValue
        );
        this.props.onSelectRow([]);
      })
      .catch((response) => {
        this.setState({ showSpinner: false });
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
        this.props.onRowSelection(false);
      });
  };

  render() {
    const ref = React.createRef();
    function handleClick() {
      // 👇️ open file input box on click of other element
      ref.current.click();
    }

    const onGridReady = (params) => {
      this.setState({ api: params.api });
      this.props.searchLocationFunction(
        moment(),
        moment(),
        "",
        this.props.dcValue
      );
    };

    const onRowSelect = (event) => {
      this.props.onRowSelection(event.node.isSelected());
      this.props.onSelectRow(this.state.api.getSelectedRows());
    };

    return (
      <div style={this.state.containerStyle}>
        <NikeDesignSystemProvider>
          <PageSpinner show={this.state.showSpinner} />
          <div style={this.state.gridStyle} className="ag-theme-alpine mb-3">
            <AgGridReact
              rowData={this.props.locationManagement}
              columnDefs={this.state.columnDefs}
              autoheight={true}
              wrapText={true}
              defaultColDef={this.state.defaultColDef}
              rowSelection={"single"}
              pagination={true}
              enableCellTextSelection={true}
              suppressHorizontalScroll={true}
              paginationPageSize={10}
              enableColResize={true}
              onGridReady={onGridReady}
              onRowSelected={onRowSelect}
            ></AgGridReact>
          </div>
          <div>
            <Form id="search-form" className={"mb-3 me-4 text-center"}>
              <Row className="mb-3 mt-5">
                <Form.Group
                  as={Col}
                  controlId="formGridItemColor export-button-icon-size"
                >
                  <Button
                    type="button"
                    test-id="import-button"
                    onClick={() => handleClick()}
                    className="me-3 button-size"
                  >
                    Import
                  </Button>

                  <Button
                    type="button"
                    test-id="submit-button"
                    disabled={this.props.selectedRows.length === 0}
                    className="ms-3 me-3 button-size"
                    theme={{
                      primary:
                        this.props.selectedRows.length !== 0
                          ? "#000000"
                          : "rgba(215,250,215,0.62)",
                      secondary:
                        this.props.selectedRows.length !== 0
                          ? "#ffffff"
                          : "rgba(165,165,165,0.62)",
                    }}
                    onClick={this.sendToMAWM}
                  >
                    Send to MAWM
                  </Button>

                  <Button
                    type="button"
                    test-id="export-button"
                    disabled={this.props.selectedRows.length === 0}
                    className="button-size ms-3"
                    theme={{
                      primary:
                        this.props.selectedRows.length !== 0
                          ? "#000000"
                          : "rgba(215,250,215,0.62)",
                      secondary:
                        this.props.selectedRows.length !== 0
                          ? "#ffffff"
                          : "rgba(165,165,165,0.62)",
                    }}
                    onClick={this.exportLocFile}
                  >
                    Export
                  </Button>
                </Form.Group>
              </Row>

              <Input
                id="file-upload"
                name="file upload"
                style={{ display: "none" }}
                ref={ref}
                type="file"
                onClick={(e) => {
                  e.currentTarget.value = null;
                }}
                onChange={this.importLocationMgmt}
              />
            </Form>
          </div>
        </NikeDesignSystemProvider>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchLocationFunction: LocationMgmtService.loadAllLocationMgmtRecords,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    locationManagement: state.locationManagement,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSearchTable);

export { LocationSearchTable };
