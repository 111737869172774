import initialState from "./initialState";

export default function loadingGroupReducer(
  state = initialState.loadingGroupList,
  action
) {
  switch (action.type) {
    case "GET_LOADING_GROUP_DETAILS":
      return action.loadingGroupList;
    case "GET_LOADING_GROUP_DETAILS_FAILED":
      return action.loadingGroupList;
    default:
      return state;
  }
}
