import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import { bindActionCreators } from "redux";
import { LoadingGroupService }  from "../../services/service.loadingGroup";
import { connect } from "react-redux";
import SpurActionButton from "./SpurActionButton";
import { Button } from "rsuite";
import { Modal } from "react-bootstrap";
import SpurPopUp from "./SpurPopUp";
import SpurActionButtonPopup from "./SpurActionButtonPopup";
import moment from "moment";

class SpurTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      loadingGroup: "",
      spur: "",
      mixedSpur: "",
      selectedRows: [],
      popupLoadingGroup: "",
      popupSpur: "",
      popupSelectedRows: [],
      isSelected: false,
      isHeaderSelected: false,
      api: null,
      containerStyle: { width: "100%", height: "50%" },
      gridStyle: { height: "113%", width: "100%" },
      containerStyle1: { width: "100%", height: "100%" },
      gridStyle1: { height: "1000%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        autoHeight: true,
        wrapText: true,
        autoHeaderHeight: true,
        wrapHeaderText: true,
      },
    };
  }

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  setPopupSelectedRows = (value) => {
    this.setState({ popupSelectedRows: value });
  };

  onPopupRowSelectionChanged = (rowsSelected, loadingGroup, spur) => {
    this.setState({popupSelectedRows: rowsSelected});
    if(rowsSelected.length>0) {
      this.setState({popupLoadingGroup: loadingGroup});
      this.setState({popupSpur: spur});
    }
  }

  render() {

    const showPopupWindow = (p) => {
      this.setState({mixedSpur: p.data.spur})
      this.setState({ showPopup: true });
    };

    const CellRendererLoadingGroup = (p) => {
      return ( p.value === "Mixed" ?
              <>
                <Button
                    className="bg-transparent p-0"
                    style={{ color: p.value === "Mixed" ? "red" : "black" }}
                    disabled={!(p.value === "Mixed")}
                    onClick={() => showPopupWindow(p)}
                >
                  {p.value}
                </Button>
              </> : p.value
      );
    };

    const numberSort = (num1: number, num2: number) => {
      return num1 - num2;
    };

    const dateTimeSort = (dateTime1: string, dateTime2: string) => {
      if (dateTime1 === "")
        return -1;
      if(dateTime2 === "")
        return 1;
      const timestamp1 = new Date(dateTime1);
      const timestamp2 = new Date(dateTime2);
      return timestamp1 - timestamp2;
    };

    const DateTimeRenderer = (p) => {
      if (p.value === "")
        return " ";
      const dateTime = new Date(p.value);
      return moment(dateTime).format("MMMM D, yyyy") + '\n' + moment(dateTime).format("hh:mm:ss A");
    };


    const RadioButtonRenderer = (params) => {
      return (
          <input
              type="radio"
              name="radioSelection"
              checked={params.node.selected}
              onChange={() => params.node.setSelected(true)}
          />
      );
    };

    const columnDefs = [
      {
        cellRenderer: RadioButtonRenderer,
        field: "selectAll",
        headerName: "",
        width: 10,
      },
      {
        headerName: "Spur",
        field: "spur",
        width: 150,
      },
      {
        headerName: "Spur Type",
        field: "spur_type",
        width: 120,
      },
      {
        headerName: "Spur Status",
        field: "status",
        autoHeight: true,
        width: 120,
      },
      {
        headerName: "Loading Group",
        field: "loading_group",
        width: 120,
        cellRenderer: CellRendererLoadingGroup,
      },
      {
        headerName: "Carrier",
        field: "carrier_id",
        width: 98,
        hide: !this.props.displayAllLGAttr
      },
      {
        headerName: "Service",
        field: "service_level_code",
        width: 101,
        hide: !this.props.displayServiceLevelCode
      },
      {
        headerName: "Shipment",
        field: "shipment_id",
        autoHeight: true,
        width: 149,
        hide: !this.props.displayAllLGAttr
      },
      {
        headerName: "GTP",
        field: "gtp_datetime",
        width: 155,
        hide: !this.props.displayGTP,
        comparator: dateTimeSort,
        cellRenderer: DateTimeRenderer
      },
      {
        headerName: "Internal Cut-off",
        field: "cutoff",
        width: 155,
        comparator: dateTimeSort,
        cellRenderer: DateTimeRenderer,
        hide: !this.props.displayAllLGAttr,
        cellStyle: { 'white-space': 'pre' }
      },
      {
        headerName: "LG Type",
        field: "lg_type",
        width: 90,
      },
      {
        headerName: "LG Status",
        field: "lg_status",
        width: 115,
      },
      { headerName: "oLPNs",
        field: "olpns",
        width: 100,
        comparator: numberSort
      },
      { headerName: "Rem oLPNs",
        field: "remaining_olpns",
        width: 130,
        comparator: numberSort
      },
      {
        headerName: "Final",
        field: "final_plan",
        width: 100,
      },
    ]
    const onGridReady = (params) => {
      this.setState({ api: params.api });
      this.props.loadSpurDetails(this.props.dcValue, "", "", "", "");
    };

    const closePopupWindow = () => {
      this.setState({
        showPopup: false,
        popupSelectedRows: [],
        popupLoadingGroup: "",
        popupSpur: "" });
    };

    const onRowSelectionChanged = () => {
      this.setState({ selectedRows: this.state.api.getSelectedRows() });
      if(this.state.api.getSelectedRows().length>0) {
        this.setState({ isSelected: true });
        this.setState({loadingGroup: this.state.api.getSelectedRows()[0].loading_group});
        this.setState({spur: this.state.api.getSelectedRows()[0].spur});
      }
      this.props.onSpurSelectRow(this.state.api.getSelectedRows());
    };

    return (
      <div style={this.state.containerStyle}>
        <div style={this.state.gridStyle} className="ag-theme-alpine mb-table">
          <AgGridReact
            rowData={this.props.spurDetailList}
            columnDefs={columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"single"}
            pagination={true}
            enableCellTextSelection={true}
            suppressHorizontalScroll={false}
            alwaysShowHorizontalScroll={true}
            paginationPageSize={7}
            onGridReady={onGridReady}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
        <div>
          <SpurActionButton
              selectedRows={this.props.spurSelectedRows}
              loadingGroup={this.state.loadingGroup}
              spur={this.state.spur}
              spurList={this.props.spurList}
              dcValue={this.props.dcValue}
              loggedInUser={this.props.loggedInUser}
              onGroupSelectRow={this.props.onGroupSelectRow}
              onSpurSelectRow={this.props.onSpurSelectRow}
              spurDetailList={this.props.spurDetailList}
              spurDetailListTotal={this.props.spurDetailListTotal}
              loadingGroupFilter={this.props.loadingGroup}
              carrier={this.props.carrier}
              cutOffStart={this.props.cutOffStart}
              cutOffEnd={this.props.cutOffEnd}
              spurFilter={this.props.spur}
              spurType={this.props.spurType}
              status={this.props.status}
              shipmentId={this.props.shipmentId}
              setCutOffEnd={this.props.setCutoffEnd}
              setCutOffStart={this.props.setCutoffStart}
              enableMultiParcelLiveSpurs={this.props.enableMultiParcelLiveSpurs}
          />
        </div>
        <Modal
          show={this.state.showPopup}
          size="xl"
          centered="true"
          onHide={closePopupWindow}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="ag-material" style={{ height: "400px" }}>
              <SpurPopUp
              mixedSpur={this.state.mixedSpur}
              onPopupRowSelectionChanged={this.onPopupRowSelectionChanged}
              dcValue={this.props.dcValue}
              loggedInUser={this.props.loggedInUser}
              onGroupSelectRow={this.props.onGroupSelectRow}
              onSpurSelectRow={this.props.onSpurSelectRow}
              displayGTP={this.props.displayGTP}
              displayServiceLevelCode={this.props.displayServiceLevelCode}
              displayAllLGAttr={this.props.displayAllLGAttr}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-sm-8">
              <SpurActionButtonPopup
                  popupSelectedRows={this.state.popupSelectedRows}
                  popupLoadingGroup={this.state.popupLoadingGroup}
                  setPopupSelectedRows={this.setPopupSelectedRows}
                  popupSpur={this.state.popupSpur}
                  closePopup={closePopupWindow}
                  spurList={this.props.spurList}
                  dcValue={this.props.dcValue}
                  loggedInUser={this.props.loggedInUser}
                  onGroupSelectRow={this.props.onGroupSelectRow}
                  onSpurSelectRow={this.props.onSpurSelectRow}
                  mixedSpur={this.state.mixedSpur}
                  spurDetailList={this.props.spurDetailList}
                  loadingGroupFilter={this.props.loadingGroup}
                  carrier={this.props.carrier}
                  cutOffStart={this.props.cutOffStart}
                  cutOffEnd={this.props.cutOffEnd}
                  spurFilter={this.props.spur}
                  spurType={this.props.spurType}
                  status={this.props.status}
                  shipmentId={this.props.shipmentId}
                  setCutOffEnd={this.props.setCutoffEnd}
                  setCutOffStart={this.props.setCutoffStart}
                  enableMultiParcelLiveSpurs={this.props.enableMultiParcelLiveSpurs}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSpurDetails: LoadingGroupService.loadSpurDetails,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    spurDetailList: state.spurDetailList,
    spurDetailListTotal: state.spurDetailListTotal,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpurTable);

export { SpurTable };
