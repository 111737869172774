import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import HashTable from "../components/Customer/HashTable";
import LocalCustomerSearch from "../components/Customer/LocalCustomerSearch";

export class HashContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      api: null,
      customerId: "",
      hashEligible: "",
    };
  }

  onSelectRow = (value) => {
    this.setState({ selectedRows: value });
  };

  onCustomerSelect = (value) => {
    this.setState({ customerId: value });
    var element = document.getElementById("customer");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(element, value);
    var ev2 = new Event("input", { bubbles: true });
    element.dispatchEvent(ev2);
  };

  onBoxLabelSelect = (value) => {
    this.setState({ boxLabelValue: value });
    var element = document.getElementById("boxLabel");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
    ).set;
    nativeInputValueSetter.call(element, value);
    var ev2 = new Event("input", { bubbles: true });
    element.dispatchEvent(ev2);
  };

  onHashSelect = (value) => {
    this.setState({ hashEligible: value });
    let element = document.getElementById("hashSelect");
    element.value = value;

    var trigger = Object.getOwnPropertyDescriptor(
      window.HTMLSelectElement.prototype,
      "value"
    ).set;
    trigger.call(element, value);
    var event = new Event("change", { bubbles: true });
    element.dispatchEvent(event);
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row mt-3 ps-3">
            <div className="col-sm-2 border-end">
              <LocalCustomerSearch
                customer={this.state.customer}
                dcValue={this.props.dcValue}
              />
            </div>

            <div className="col-sm-9 px-2">
              <HashTable
                onCustomerSelect={this.onCustomerSelect}
                onHashSelect={this.onHashSelect}
                onBoxLabelSelect={this.onBoxLabelSelect}
                customerId={this.state.customerId}
                hashEligible={this.state.hashEligible}
                loggedInUser={this.props.loggedInUser}
                onSelectRow={this.onSelectRow}
                selectedRows={this.state.selectedRows}
                dcValue={this.props.dcValue}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HashContainer;
