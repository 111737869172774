import React from "react";
import { Tabs } from "@nike/epic-react-ui";
import "./component.css";
import { TabPanel } from "react-tabs";
import SpecialInstructionContainer from "../containers/SpecialInstructionsContainer";
import HashContainer from "../containers/HashContainer";
import CarrierHubContainer from "../containers/CarrierHubContainer";
import LaunchContainer from "../containers/LaunchContainer";
import { useLocation } from "react-router-dom";
import { PullQuote } from "@nike/nike-design-system-components";
import Header from "../components/Header";
import LocationManagementContainer from "../containers/LocationManagementContainer";
import LoadingGroupContainer from "../containers/LoadingGroupContainer";
import config from "../config/config.js";

function RoutingPanel(props) {
  let tabsToDisplay = []; //["Item Special Instructions", "Customers", "Carrier + hub"];
  const adGrps = props.adGroup;
  const location = useLocation();
  let tabData = [];

  const handleIndex = (index) => {
    sessionStorage.setItem("index", index);
  };

  if (undefined !== location && undefined !== location.state) {
    const destLocCode = location.state.dc;
    let dbAdGrps = location.state.adGroups;

    var env = config.isProd ? "prod" : "non-prod";
    var globalADGroup;
    var spclInstrnADGroup;
    var spclInstrnOldProdADGroup;
    var customerConfigADGroup;
    var carrierHubADGroup;
    var launchADGroup;
    var locationmanagementADGroup;
    var loadingGroupADGroup;

    if (env === "non-prod") {
     globalADGroup =
      "App.NodePlatform.NodeConfigUI.Admin.Test.Users." + destLocCode;
     spclInstrnADGroup =
      "App.NodePlatform.NodeConfigUI.SpclInstruction.Test.Users." + destLocCode;
     customerConfigADGroup =
      "App.NodePlatform.NodeConfigUI.Customer.Test.Users." + destLocCode;
     carrierHubADGroup =
      "App.NodePlatform.NodeConfigUI.CarrierHub.Test.Users." + destLocCode;
     launchADGroup =
       "App.NodePlatform.NodeConfigUI.Launch.Test.Users." + destLocCode;
     locationmanagementADGroup =
      "App.NodePlatform.NodeConfigUI.LocationMgmt.Test.Users." + destLocCode;
     loadingGroupADGroup =
      "App.NodePlatform.NodeConfigUI.LoadingGroup.Test.Users." + destLocCode;
    } else if (env === "prod") {
      globalADGroup =
          "App.NodePlatform.NodeConfigUI.Admin.Prod.Users." + destLocCode;
      spclInstrnADGroup =
          "App.NodePlatform.NodeConfigUI.SpclInstruction.Prod.Users." +
          destLocCode;
      customerConfigADGroup =
          "App.NodePlatform.NodeConfigUI.Customer.Prod.Users." + destLocCode;
      spclInstrnOldProdADGroup =
          "App.NodePlatform.NodeExtn.SpclInstruction.Prod.Users";
      launchADGroup =
          "App.NodePlatform.NodeConfigUI.Launch.Prod.Users." + destLocCode;
      locationmanagementADGroup =
          "App.NodePlatform.NodeConfigUI.LocationMgmt.Prod.Users." + destLocCode;
      loadingGroupADGroup =
          "App.NodePlatform.NodeConfigUI.LoadingGroup.Prod.Users." + destLocCode;
    }
    let spclInstrnTabPresent = false;
    let custConfigTabPresent = false;
    let carrierHubTabPresent = false;
    let launchTabPresent = false;
    let locationManagementTabPresent = false;
    let loadingGroupTabPresent = false;

    if (undefined !== adGrps && undefined !== dbAdGrps && dbAdGrps.length > 0) {
      dbAdGrps.forEach((resData) => {
        if (undefined !== adGrps && adGrps.includes(resData)) {
          if (
            !spclInstrnTabPresent &&
            (globalADGroup === resData ||
              spclInstrnADGroup === resData ||
              spclInstrnOldProdADGroup === resData) &&
            destLocCode === "1067"
          ) {
            spclInstrnTabPresent = true;
            tabsToDisplay.push("Item Special Instructions");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs">
                <SpecialInstructionContainer
                  loggedInUser={props.loggedInUser}
                />
              </TabPanel>
            );
          }
          if (
            !custConfigTabPresent &&
            (globalADGroup === resData || customerConfigADGroup === resData) &&
            (destLocCode === "1067" || destLocCode === "1065")
          ) {
            custConfigTabPresent = true;
            tabsToDisplay.push("Customers");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs" key="hashContainer">
                <HashContainer
                  loggedInUser={props.loggedInUser}
                  dcValue={location.state.dc}
                />
              </TabPanel>
            );
          }
          if (
            carrierHubTabPresent &&
            (globalADGroup === resData || carrierHubADGroup === resData) &&
              (destLocCode === "1065")
          ) {
            carrierHubTabPresent = true;
            tabsToDisplay.push("Carrier + hub");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs" key="carrierHubContainer">
                <CarrierHubContainer
                  loggedInUser={props.loggedInUser}
                  dcValue={location.state.dc}
                />
              </TabPanel>
            );
          }
          if (
            !launchTabPresent &&
            (globalADGroup === resData || launchADGroup === resData) &&
            destLocCode === "1065"
          ) {
            launchTabPresent = true;
            tabsToDisplay.push("Launch");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs" key="launchContainer">
                <LaunchContainer
                  loggedInUser={props.loggedInUser}
                  dcValue={location.state.dc}
                />
              </TabPanel>
            );
          }
          if (
            !locationManagementTabPresent &&
            (globalADGroup === resData ||
              locationmanagementADGroup === resData) &&
            destLocCode === "1065"
          ) {
            locationManagementTabPresent = true;
            tabsToDisplay.push("Location Management");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs" key="locationContainer">
                <LocationManagementContainer
                  loggedInUser={props.loggedInUser}
                  dcValue={location.state.dc}
                />
              </TabPanel>
            );
          }
          if (
            !loadingGroupTabPresent &&
            (globalADGroup === resData || loadingGroupADGroup === resData) &&
            (destLocCode === "1065" || destLocCode === "1093")
          ) {
            loadingGroupTabPresent = true;
            tabsToDisplay.push("Loading Group Assignment");
            tabData.push(
              <TabPanel className="tabPanel bg-white padding-tabs" key="loadingGroupContainer">
                <LoadingGroupContainer
                  loggedInUser={props.loggedInUser}
                  dcValue={location.state.dc}
                />
              </TabPanel>
            );
          }
        }
      });
    }

    if (!(tabsToDisplay.length > 0) || !(tabData.length > 0)) {
      tabData.push(
        <TabPanel className="tabPanel bg-white padding-tabs" key="unauthorised">
          <div className="center w-auto">
            <PullQuote
              className="h1"
              text="You are not authorised to view this page. Please contact administrator."
            />
          </div>
        </TabPanel>
      );
    }
  }

  return (
    <div>
      <Header
        handleLogout={props.handleLogout}
        displayLogout={props.displayLogout}
        dcLabel={location.state.dcLabel}
      />
      <Tabs
        className="tabs bg-color-tabs"
        selectedTabPanelClassName="activeTabPanel"
        tabs={tabsToDisplay}
        defaultIndex={sessionStorage.getItem("index")}
        onSelect={(tabId) => {
          handleIndex(tabId);
        }}
      >
        <div>{tabData}</div>
      </Tabs>
    </div>
  );
}
export default RoutingPanel;
