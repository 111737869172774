export const SEARCH_LCTN_MGMT_SUCCESS = "SEARCH_LCTN_MGMT_SUCCESS";
export const SEARCH_LCTN_MGMT_FAILED = "SEARCH_LCTN_MGMT_FAILED";

export function searchLocationMgmtSuccess(locationManagement) {
  return {
    type: SEARCH_LCTN_MGMT_SUCCESS,
    locationManagement,
  };
}

export function searchLocationMgmtFailed(locationManagement) {
  return {
    type: SEARCH_LCTN_MGMT_FAILED,
    locationManagement,
  };
}
