import initialState from "./initialState";

export default function carrierHubReducer(
    state = initialState.carrierHubs,
    action
) {
    switch (action.type) {
        case "GET_CARRIER_HUBS":
            return action.carrierHubs;
        case "CARRIER_HUB_SEARCH_FAILED":
            return action.carrierHubs;
        default:
            return state;
    }
}
