import React, { useEffect, useState } from "react";
import { IconButton } from "@nike/epic-react-ui";
import { Form, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
} from "@nike/nike-design-system-components";

function Search(props) {
  const [itemStyle, setItemStyle] = useState("");
  const [itemColor, setItemColor] = useState("");
  const [isValidItemStyle, setIsValidItemStyle] = useState(true);
  const [isInputValid, setIsInputValid] = useState(true);

  useEffect(() => {
    async function validateData() {
      if (isValidItemStyle) {
        setIsInputValid(true);
      } else {
        setIsInputValid(false);
      }
    }
    validateData();
  }, [isValidItemStyle]);

  const searchFunction = () => {
    if (props.itemStyle === "") {
      setIsValidItemStyle(false);
      setIsInputValid(false);
    } else {
      props.searchStyleFunction(props.itemStyle, props.itemColor);
      props.onChangeSearchMAWM(true);
    }
  };

  const handleItemStyle = (itemStyleValue) => {
    //	event.preventDefault();
    setItemStyle(itemStyleValue);
    console.log("Item Style value ", itemStyle);
    setIsValidItemStyle(itemStyleValue !== "");
    props.onChangeItemStyle(itemStyleValue);
  };

  const handleItemColor = (itemColorValue) => {
    setItemColor(itemColorValue);
    console.log("Item Color value ", itemColor);
    props.onChangeItemColor(itemColorValue);
  };

  return (
    <div>
      <Form id="search-form">
        <NikeDesignSystemProvider>
        <Row className="d-flex justify-content-around align-items-center row py-4">
          <Form.Group as={Col}>
            <TextFieldStyled
                type="text"
                id="itemStyle"
                test-id="item-style"
                placeholder="Item Style"
                label="Item Style"
                requiredIndicator="*"
                maxLength={15}
                required={true}
                className="mt-2"
                onChange={(event) => handleItemStyle(event.target.value)}
                // value={props.itemStyle}
                isInvalid={!isValidItemStyle}
                ariaLive="polite"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <TextFieldStyled
                test-id="item-color"
                type="text"
                placeholder="Item Color"
                label="Item Color"
                maxLength={15}
                className="mt-2"
                id="itemColor"
                //   value={props.itemColor}
                onChange={(event) => handleItemColor(event.target.value)}
                ariaLive="polite"
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-1">
            <IconButton
              type="NikeApp"
              test-id="Search-mawm-button"
              iconSize="m"
              disabled={!isInputValid}
              onClick={() => searchFunction()}
              disabledTip={{
                message: "Item Style is a Mandatory field",
                position: "right",
                size: "medium",
              }}
              theme={{
                primary: isInputValid ? "#000000" : "rgba(215,250,215,0.62)",
                secondary: isInputValid ? "#ffffff" : "rgba(165,165,165,0.62)",
              }}
            >
              Search MAWM
            </IconButton>
          </Form.Group>
        </Row>
        </NikeDesignSystemProvider>
      </Form>
    </div>
  );
}
const mapStateToProps = (state) => ({
  instrcns: state.instrcns,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchStyleFunction: SpecialInstructionService.getStyle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Search);

export { Search };
