import React, {useState} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    NikeDesignSystemProvider,
    DropDownStyled, Input,
} from "@nike/nike-design-system-components";
import {Col, Form, Row} from "react-bootstrap";
import {Button, PageSpinner} from "@nike/epic-react-ui";
import {LaunchValidationService} from "../../services/service.launchValidation";
import {toast} from "react-toastify";
import fileDownload from "js-file-download";

function Launch() {

    const [launchType, setLaunchType] = useState("Launch_Exclusions_secure");
    const [showSpinner , setShowSpinner] = useState(false);
    const ref = React.createRef();

    function handleFile(launchType) {
        setLaunchType(launchType);
    }

    function handleClick() {
        // 👇️ open file input box on click of other element
        ref.current.click();
    }

    async function save(e) {
        setShowSpinner(true);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("launchType", launchType);
        await LaunchValidationService.importLaunchFile(formData,launchType)
            .then(() => {
                setShowSpinner(false);
                toast.success("File saved successfully");
            })
            .catch((response) => {
                setShowSpinner(false);
                if (response !== undefined && response.data !== undefined)
                    toast.error(response.data.message);
            });
    }

    const exportLaunchFile = async () => {
        setShowSpinner(true);
        await LaunchValidationService.exportLaunchFIle(launchType).then((res) => {
            fileDownload(
                res.data,
                launchType+".xlsx",
                "text/csv"
            );
            toast.success("File downloaded successfully");
            setShowSpinner(false);
        })
        .catch(() => {
            setShowSpinner(false);
            toast.error("Failed to save file");
        })
    }

    return (
      <div>
          <PageSpinner show={showSpinner} />
          <NikeDesignSystemProvider>
              <Row className="py-5 mt-5">
                  <Form.Group as={Col}>
                      <Form.Label className={"fw-bold"}>File Name</Form.Label>
                      <DropDownStyled
                          name="launchType"
                          id="launchType"
                          value={launchType}
                          autoComplete="off"
                          disabled={false}
                          onChange={(event) => {
                              handleFile(event.target.value);
                          }}
                          options={[
                              { label: "Launch_Exclusions_secure", value: "Launch_Exclusions_secure" },
                              { label: "GEO_LIST", value: "GEO_LIST" },
                              { label: "Launch_Prelaunches", value: "Launch_Prelaunches" },
                              { label: "Launch_Holidays", value: "Launch_Holidays" },
                              { label: "CWPT_customer", value: "CWPT_customer" },
                              { label: "Launch_Exclusions", value: "Launch_Exclusions" },

                          ]}
                          ariaLive="polite"
                      />
                  </Form.Group>
              </Row>

              <Input
                  id="file-upload"
                  name="file upload"
                  style={{display: 'none'}}
                  ref={ref}
                  type="file"
                  onClick={(e)=> {e.currentTarget.value = null}}
                  onChange={(e)=>save(e)}
              />


              <div className="py-3 mt-5">
                  <Button
                      type="NikeApp"
                      iconsize="m"
                      test-id="import-button"
                      onClick={() => {handleClick()}}
                  >
                      Import
                  </Button>
                  <Button
                      iconsize="m"
                      test-id="export-button"
                      className="float-end"
                      onClick={() => exportLaunchFile()}
                  >
                      Export
                  </Button>
              </div>
          </NikeDesignSystemProvider>
      </div>
  );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            importLaunchFile: LaunchValidationService.importLaunchFile,
        },
        dispatch
    );

function mapStateToProps(state) {
    return {
        launch: state.launch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Launch);

export { Launch };
