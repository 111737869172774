/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { IconButton, PageSpinner } from "@nike/epic-react-ui";
import { Button } from "@nike/epic-react-ui";
import { Form, Col, Row, Modal } from "react-bootstrap";
import "../component.css";
import fileDownload from "js-file-download";
import API_INTERFACE_URI from "../../constants/uri.constants";
import "rsuite/dist/rsuite.min.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
  DropDownStyled,
  DatePickerStyled,
} from "@nike/nike-design-system-components";
import moment from "moment";


function LocalDBSearch(props) {
  const [itemStyle, setItemStyle] = useState("");
  const [itemColor, setItemColor] = useState("");
  const [user, setUser] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isValidCriteria, setValidCriteria] = useState(false);
  const [showPageSpinner, setshowPageSpinner] = useState(false);

  const [isValidItemStyle, setIsValidItemStyle] = useState(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalShow = () => setShowAlertModal(true);
  const handleAlertModalClose = () => setShowAlertModal(false);

  const handleItemStyle = (itemStyleValue) => {
    setItemStyle(itemStyleValue);
    setIsValidItemStyle(!(itemStyleValue === "" && itemColor !== ""));
    setValidCriteria(itemStyleValue !== "");
    props.onChangeItemStyleLocal(itemStyleValue);
  };

  const handleItemColor = (itemColorValue) => {
    setIsValidItemStyle(!(itemStyle === "" && itemColorValue !== ""));
    setItemColor(itemColorValue);
    if (!(itemStyle === "" && itemColorValue !== "")) setValidCriteria(true);
    props.onChangeItemColorLocal(itemColorValue);
  };

  const handleUser = (userValue) => {
    if (userValue !== "") setValidCriteria(true);
    setUser(userValue);
    props.onChangeUserLocal(userValue);
  };

  const handleToDate = (toDate) => {
    setToDate(toDate);
    if(fromDate!=="" && toDate!=="")
      setValidCriteria(true);
    else
      setValidCriteria(false);
    props.onChangeToDateLocal(toDate);
  };

  const handleFromDate = (fromDate) => {
    setFromDate(fromDate);
    if(toDate!==""&&fromDate!=="")
      setValidCriteria(true);
    else
      setValidCriteria(false);
    props.onChangeFromDateLocal(fromDate);
  };

  const handleOnClick = () => {
    if (
      itemStyle === "" &&
      itemColor === "" &&
      user === "" &&
      props.toDateLocal === "" &&
      props.fromDateLocal === ""
    ) {
      handleAlertModalShow();
    } else {
      props.onChangeSearchMAWM(false);
      props.localSearchStyleFunction(
        props.itemStyleLocal,
        props.itemColorLocal,
        props.fromDateLocal,
        props.toDateLocal,
        props.userLocal
      );
    }
  };

  const exportAll = async () => {
    setshowPageSpinner(true);
    await SpecialInstructionService.exportAll().then((res) => {
      fileDownload(
        res.data,
        API_INTERFACE_URI.EXPORT_ALL_FILE_NAME,
        "text/csv"
      );
      setshowPageSpinner(false);
    });
  };

  return (
    <Form id="search-form" className={" row "}>
      <NikeDesignSystemProvider>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label className={"fw-bold"}>Instruction type</Form.Label>
          <DropDownStyled
              name="instructionType"
              id="instructionType"
              value="Item Instructions"
              disabled={false}
              options={[
                { label: "Item Instructions", value: "Item Instructions" },
              ]}
                />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <TextFieldStyled
              type="text"
              placeholder="Item Style"
              maxLength={15}
              id="itemStyleLocalDb"
              label="Item Style"
              requiredIndicator="*"
              test-id="item-style-local"
              error={!isValidItemStyle}
              onChange={(event) => handleItemStyle(event.target.value)}
              ariaLive="polite"
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <TextFieldStyled
              type="text"
              placeholder="Item Color"
              maxLength={15}
              label="Item Color"
              id="itemColorLocalDB"
              test-id="item-color-local"
              //  value={itemColor}
              onChange={(event) => handleItemColor(event.target.value)}
              ariaLive="polite"
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <TextFieldStyled
              type="text"
              placeholder="User"
              maxLength={40}
              label="User"
              onChange={(event) => handleUser(event.target.value)}
              id="itemUserLocalDB"
              test-id="item-user-local"
              ariaLive="polite"
          />
        </Form.Group>
      </Row>

      <Row className="mt-3">
        <Form.Group as={Col}>
          <Form.Label className={"fw-bold"}>Date Range</Form.Label>
          <DatePickerStyled
              onChange={(event) => handleFromDate(event.target.value)}
              test-id="item-from-date-local"
              label="From"
              max={toDate==="" ? moment().format("yyyy-MM-DD") : moment(toDate).format("yyyy-MM-DD")}
              min={toDate==="" ? undefined : moment(toDate).subtract(6,"days").format("yyyy-MM-DD")}
          />
          <DatePickerStyled
              onChange={(event) => handleToDate(event.target.value)}
              label="To"
              test-id="item-to-date-local"
              max={fromDate!=="" ? moment(fromDate).add(6,"days").isAfter(moment()) ? moment().format("yyyy-MM-DD") : moment(fromDate).add(6,"days").format("yyyy-MM-DD") : moment().format("yyyy-MM-DD")}
              min={fromDate}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col}>
          <IconButton
            type="NikeApp"
            test-id="local-search-button"
            iconSize="m"
            onClick={() => handleOnClick()}
            disabled={!isValidCriteria}
            disabledTip={{
              message: "Item Style is a Mandatory field",
              position: "right",
              size: "medium",
            }}
            theme={{
              primary: isValidCriteria ? "#000000" : "rgba(215,250,215,0.62)",
              secondary: isValidCriteria ? "#ffffff" : "rgba(165,165,165,0.62)",
            }}
          >
            Local Search
          </IconButton>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="formGridItemColor export-button-icon-size"
        >
          <Button
            type="button"
            test-id="export-all-button"
            onClick={() => exportAll()}
            className="export-button-icon-size"
          >
            Export All
          </Button>
          {showPageSpinner && <PageSpinner show={showPageSpinner} />}
        </Form.Group>
      </Row>
      <Modal show={showAlertModal} onHide={handleAlertModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please select a search criteria</Modal.Body>
      </Modal>
      </NikeDesignSystemProvider>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    instrcns: state.instrcns,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      localSearchStyleFunction:
        SpecialInstructionService.searchInstructionService,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocalDBSearch);

export { LocalDBSearch };
