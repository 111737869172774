import React, { useState, useEffect } from "react";
import { Button } from "@nike/epic-react-ui";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportCSV from "./ExportCSV";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import {
  NikeDesignSystemProvider,
  TextAreaStyled,
} from "@nike/nike-design-system-components";

function ButtonPanel(props) {
  const [instruction, setInstruction] = useState("");
  const [validAddUpdate, setValidAddUpdate] = useState(true);
  const [show, setShow] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAlertModalShow = () => setShowAlertModal(true);
  const handleAlertModalClose = () => setShowAlertModal(false);

  const handleInstructions = (_instruction) => {
    setInstruction(_instruction);
    setValidAddUpdate(_instruction !== "");
  };
  const handleDelete = async () => {
    await deleteSpecialInstructions(props.selectedRows);
    handleClose();
    props.searchStyleFunction(props.itemStyle, props.itemColor);
    props.onRowSelection(false);
    props.onSelectRow([]);
  };

  useEffect(() => {
    async function validateData() {
      if (validAddUpdate) {
        setValidAddUpdate(true);
      } else {
        setValidAddUpdate(false);
      }
    }
    validateData();
  }, [validAddUpdate]);

  const addUpdate = async (e) => {
    e.preventDefault();
    if (instruction === "") {
      setValidAddUpdate(false);
    } else {
      if (props.selectedRows.length === 0) {
        handleAlertModalShow();
      } else {
        await updateSpecialInstructions(props.selectedRows, instruction);
        await props.searchStyleFunction(props.itemStyle, props.itemColor);
        props.onRowSelection(false);
        props.onSelectRow([]);
        setInstruction("");
      }
    }
  };

  async function updateSpecialInstructions(body, instruction) {
    let payload = {};
    const toastMessage = "Special instructions updated successfully";
    body.forEach((temp) => {
      delete temp.create_timestamp;
      delete temp.update_timestamp;
      temp.special_instrns = instruction;
      if (props.loggedInUser !== "") {
        temp.user = props.loggedInUser;
      }
    });
    payload = { styles: body };
    await SpecialInstructionService.updateSpecialInstructions(payload)
      .then((response) => {
        toast.success(toastMessage);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }

  async function deleteSpecialInstructions(body) {
    let payload = {};
    const toastMessage = "Special instructions removed successfully";
    body.forEach((temp) => {
      delete temp.create_timestamp;
      delete temp.update_timestamp;
      if (props.loggedInUser !== "") {
        temp.user = props.loggedInUser;
      }
    });
    payload = { styles: body };
    await SpecialInstructionService.deleteSpecialInstructions(payload)
      .then((response) => {
        toast.success(toastMessage);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }

  return (
    <div className=" row ">
      <NikeDesignSystemProvider>
      <div className=" col-sm-6 mb-4">
        <TextAreaStyled
            rows={4}
            id="inst-text"
            test-id="instruction-text"
            readOnly = {!props.isSearchMAWM}
            resize="vertical"
            onChange={(event) => handleInstructions(event.target.value)}
            placeholder="Instruction Text: Use ; as a delimiter for multiple Instructions."
            value={instruction}
        ></TextAreaStyled>
      </div>

      <div>
        <Form>
          <div className="inline-block">
            <Button
              medium="true"
              test-id="cancel-button"
              disabled={!props.isSearchMAWM}
              onClick={() => {
                setInstruction("");
              }}
              className="me-5 ms-2"
              theme={{
                primary: props.isSearchMAWM
                  ? "#000000"
                  : "rgba(215,250,215,0.62)",
                secondary: props.isSearchMAWM
                  ? "#ffffff"
                  : "rgba(165,165,165,0.62)",
              }}
            >
              Cancel
            </Button>

            <ExportCSV></ExportCSV>

            <Button
              medium="true"
              test-id="delete-button"
              disabled={!props.isSearchMAWM}
              className="me-5 ms-2"
              theme={{
                primary: props.isSearchMAWM
                  ? "#000000"
                  : "rgba(215,250,215,0.62)",
                secondary: props.isSearchMAWM
                  ? "#ffffff"
                  : "rgba(165,165,165,0.62)",
              }}
              onClick={() => {
                if (props.selectedRows.length === 0) {
                  handleAlertModalShow();
                } else {
                  handleShow();
                }
              }}
            >
              Remove
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>Do you want to delete the record(s)?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showAlertModal} onHide={handleAlertModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please select a record(s)</Modal.Body>
            </Modal>

            <Button
              type="NikeApp"
              test-id="add-update-button"
              iconsize="m"
              disabled={!validAddUpdate || !props.isSearchMAWM}
              onClick={(e) => addUpdate(e)}
              disabledTip={{
                message: "Instruction is a Mandatory field for Add/Update",
                position: "right",
                size: "medium",
              }}
              theme={{
                primary:
                  validAddUpdate && props.isSearchMAWM
                    ? "#000000"
                    : "rgba(215,250,215,0.62)",
                secondary:
                  validAddUpdate && props.isSearchMAWM
                    ? "#ffffff"
                    : "rgba(165,165,165,0.62)",
              }}
            >
              Add/Update
            </Button>
          </div>
        </Form>
      </div>
      </NikeDesignSystemProvider>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    instrcns: state.instrcns,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchStyleFunction: SpecialInstructionService.getStyle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPanel);
export { ButtonPanel };
