import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import {Grid, GridItem} from "@nike/nike-design-system-components";
import LoadingGroupSearch from "../components/LoadingGroup/LoadingGroupSearch";
import SpurSearch from "../components/LoadingGroup/SpurSearch";
import Assign from "../components/LoadingGroup/Assign";
import SpurTable from "../components/LoadingGroup/SpurTable";
import LoadingGroupTable from "../components/LoadingGroup/LoadingGroupTable";
import LoadingGroupButtonPanel from "../components/LoadingGroup/LoadingGroupButtonPanel";
import moment from "moment";
import API_INTERFACE_URI from "../constants/uri.constants";
import {globalHeaders} from "../utilities/http";

export class LoadingGroupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupSelectedRows: [],
      spurSelectedRows: [],
      api: null,
      loadingGroup: "",
      carrier: "",
      cutOffStart: "",
      cutOffEnd: moment().endOf("day").format("YYYY-MM-DD[T]HH:mm"),
      spurList: [],
      spur: "",
      spurType: "",
      spurTypeList: [],
      status: "",
      statusList: ["Assigned", "Unassigned"],
      shipmentId: "",
      displayGTP: false,
      displayServiceLevelCode: false,
      displayAllLGAttr: false,
      enableMultiParcelLiveSpurs: false,
    };
  }

  componentDidMount() {
    fetch(API_INTERFACE_URI.LOADING_GROUP_FLAG_VALUES + "?destLocCode=" + this.props.dcValue, {
      method: 'get',
      dataType: 'json',
      headers: globalHeaders
    })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.setState({displayGTP: data.displayGTP});
          this.setState({displayServiceLevelCode: data.displayServiceLevelCode});
          this.setState({displayAllLGAttr: data.displayAllLGAttr});
          this.setState({enableMultiParcelLiveSpurs: data.enableMultiParcelLiveSpurs});
        });
  }

  onLoadingGroupChange = (value) => {
    this.setState({ loadingGroup: value });
  };

  onCarrierChange = (value) => {
    this.setState({ carrier: value });
  };

  onCutOffStartChange = (value) => {
    this.setState({ cutOffStart: value });
  };

  onCutOffEndChange = (value) => {
    this.setState({ cutOffEnd: value });
  };

  onGroupSelectRow = (value) => {
    this.setState({ groupSelectedRows: value });
  };

  onSpurListFetch = (value) => {
    this.setState({ spurList: value });
  };

  onSpurTypeListFetch = (value) => {
    this.setState({ spurTypeList: value });
  };

  resetSpurFilters = () => {
    this.setState({ spur: "" });
    this.setState({ spurType: "" });
    this.setState({ status: "" });
    this.setState({ shipmentId: "" });
  };

  setSpur = (value) => {
    this.setState({ spur: value });
  };

  setSpurType = (value) => {
    this.setState({ spurType: value });
  };

  setStatus = (value) => {
    this.setState({ status: value });
  };

  setShipmentId = (value) => {
    this.setState({ shipmentId: value });
  };

  onSpurSelectRow = (value) => {
    this.setState({ spurSelectedRows: value });
  };

  setCutoffEnd = (value) => {
    this.setState( {cutOffEnd: value})
  };

  setCutoffStart = (value) => {
    this.setState( {cutOffStart: value})
  };

  render() {
    const cellStyle = {
      width: "100%",
      height: "100%",
      minHeight: "60px",
      borderRadius: "20px",
      border: "1px solid",
    };
    return (

      <div className="container-fluid">
        <div className="row">
          <Grid rowGapXs="12px">
            <GridItem xs={5.5} l={20}>
              <div style={cellStyle} className="col-sm-6 px-2">
                <LoadingGroupSearch
                  onLoadingGroupChange={this.onLoadingGroupChange}
                  onCarrierChange={this.onCarrierChange}
                  onCutOffStartChange={this.onCutOffStartChange}
                  onCutOffEndChange={this.onCutOffEndChange}
                  loadingGroup={this.state.loadingGroup}
                  carrier={this.state.carrier}
                  cutOffStart={this.state.cutOffStart}
                  cutOffEnd={this.state.cutOffEnd}
                  dcValue={this.props.dcValue}
                />
                <LoadingGroupTable
                  onGroupSelectRow={this.onGroupSelectRow}
                  groupSelectedRows={this.state.groupSelectedRows}
                  loadingGroup={this.state.loadingGroup}
                  carrier={this.state.carrier}
                  cutOffStart={this.state.cutOffStart}
                  cutOffEnd={this.state.cutOffEnd}
                  dcValue={this.props.dcValue}
                  loggedInUser={this.props.loggedInUser}
                  spur={this.state.spur}
                  spurType={this.state.spurType}
                  status={this.state.status}
                  setCutOffEnd={this.setCutoffEnd}
                  setCutOffStart={this.setCutoffStart}
                  displayGTP={this.state.displayGTP}
                  displayServiceLevelCode={this.state.displayServiceLevelCode}
                />
              </div>
            </GridItem>
            <GridItem xs={1} children="h-50">
              <Assign
                  groupSelectedRows={this.state.groupSelectedRows}
                  spurSelectedRows={this.state.spurSelectedRows}
                  dcValue={this.props.dcValue}
                  loggedInUser={this.props.loggedInUser}
                  onGroupSelectRow={this.onGroupSelectRow}
                  onSpurSelectRow={this.onSpurSelectRow}
                  loadingGroup={this.state.loadingGroup}
                  carrier={this.state.carrier}
                  cutOffStart={this.state.cutOffStart}
                  cutOffEnd={this.state.cutOffEnd}
                  spur={this.state.spur}
                  spurType={this.state.spurType}
                  status={this.state.status}
                  shipmentId={this.state.shipmentId}
                  setCutOffEnd={this.setCutoffEnd}
                  setCutOffStart={this.setCutoffStart}
                  enableMultiParcelLiveSpurs={this.state.enableMultiParcelLiveSpurs}
              />
            </GridItem>
            <GridItem xs={5.5}>
              <div style={cellStyle} className="col-sm-6 px-2">
                <SpurSearch
                  dcValue={this.props.dcValue}
                  onSpurListFetch={this.onSpurListFetch}
                  onSpurTypeListFetch={this.onSpurTypeListFetch}
                  resetSpurFilters={this.resetSpurFilters}
                  setSpur={this.setSpur}
                  setSpurType={this.setSpurType}
                  setStatus={this.setStatus}
                  setShipmentId={this.setShipmentId}
                  spur={this.state.spur}
                  spurType={this.state.spurType}
                  status={this.state.status}
                  spurList={this.state.spurList}
                  spurTypeList={this.state.spurTypeList}
                  statusList={this.state.statusList}
                  shipmentId={this.state.shipmentId}
                />
                <SpurTable
                  dcValue={this.props.dcValue}
                  onSpurSelectRow={this.onSpurSelectRow}
                  spurSelectedRows={this.state.spurSelectedRows}
                  spurList={this.state.spurList}
                  loggedInUser={this.props.loggedInUser}
                  onGroupSelectRow={this.onGroupSelectRow}
                  loadingGroup={this.state.loadingGroup}
                  carrier={this.state.carrier}
                  cutOffStart={this.state.cutOffStart}
                  cutOffEnd={this.state.cutOffEnd}
                  spur={this.state.spur}
                  spurType={this.state.spurType}
                  status={this.state.status}
                  shipmentId={this.state.shipmentId}
                  setCutOffEnd={this.setCutoffEnd}
                  setCutOffStart={this.setCutoffStart}
                  displayGTP={this.state.displayGTP}
                  displayServiceLevelCode={this.state.displayServiceLevelCode}
                  displayAllLGAttr={this.state.displayAllLGAttr}
                  enableMultiParcelLiveSpurs={this.state.enableMultiParcelLiveSpurs}
                />
              </div>
            </GridItem>
          </Grid>
        </div>
        <div className="row">
          <LoadingGroupButtonPanel
            dcValue={this.props.dcValue}
            loggedInUser={this.props.loggedInUser}
          />
        </div>
      </div>
    );
  }
}

export default LoadingGroupContainer;
