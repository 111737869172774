import initialState from "./initialState";

export default function LaunchReducer(
    state = initialState.launch,
    action
) {
    switch (action.type) {
        case "EXPORT_FILE":
            return action.launch;
        default:
            return state;
    }
}
