export const GET_LOADING_GROUP_DETAILS = "GET_LOADING_GROUP_DETAILS";
export const GET_LOADING_GROUP_DETAILS_FAILED = "GET_LOADING_GROUP_DETAILS_FAILED";

export function getLoadingGroupDetails(loadingGroupList) {
  return {
    type: GET_LOADING_GROUP_DETAILS,
    loadingGroupList,
  };
}

export function getLoadingGroupDetailsFailed(loadingGroupList) {
  return {
    type: GET_LOADING_GROUP_DETAILS_FAILED,
    loadingGroupList,
  };
}
