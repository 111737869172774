import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import LocationSearch from "../components/LocationManagement/LocationSearch";
import LocationSearchTable from "../components/LocationManagement/LocationSearchTable";
import moment from "moment";

export class LocationManagementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      isSelected: false,
      api: null,
      localUser: "",
      localFromDate: moment(),
      localToDate: moment(),
    };
  }

  onSelectRow = (value) => {
    this.setState({ selectedRows: value });
  };

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  handleLocalUser = (userValue) => {
    this.setState({ localUser: userValue });
  };

  handleLocalToDate = (toDate) => {
    this.setState({ localToDate: toDate });
  };

  handleLocalFromDate = (fromDate) => {
    this.setState({ localFromDate: fromDate });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row  mt-3 ps-3">
            <div className="col-sm-2 border-end">
              <LocationSearch
                dcValue={this.props.dcValue}
                onSelectRow={this.onSelectRow}
                handleLocalFromDate={this.handleLocalFromDate}
                handleLocalToDate={this.handleLocalToDate}
                handleLocalUser={this.handleLocalUser}
              />
            </div>
            <div className="col-sm-9 px-4 mt-2">
              <LocationSearchTable
                selectedRows={this.state.selectedRows}
                onSelectRow={this.onSelectRow}
                onRowSelection={this.onRowSelection}
                loggedInUser={this.props.loggedInUser}
                dcValue={this.props.dcValue}
                isSelected={this.state.isSelected}
                localUser={this.state.localUser}
                localFromDate={this.state.localFromDate}
                localToDate={this.state.localToDate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationManagementContainer;
