import React, { useState, useEffect, useRef } from "react";
import { Button } from "@nike/epic-react-ui";
import "react-toastify/dist/ReactToastify.css";
import { Form, Col, Row, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HashCustomerService } from "../../services/service.hashCustomers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DropDownStyled,
  NikeDesignSystemProvider,
  TextFieldStyled,
} from "@nike/nike-design-system-components";

function Customer(props) {
  const [validSave, setValidSave] = useState(false);
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [hashValue, setHashValue] = useState("");
  const [boxLabelValue, setBoxLabelValue] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalShow = () => setShowAlertModal(true);
  const handleAlertModalClose = () => setShowAlertModal(false);
  const firstRender = useRef(true);
  const handleCustomer = (customer) => {
    setCustomerId(customer);
  };

  const handleHash = (hash) => {
    setHashValue(hash);
  };

  const handleBoxLabel = (boxLabel) => {
    setBoxLabelValue(boxLabel);
  };

  useEffect(() => {
    setValidSave(validateSave());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, hashValue]);

  const validateSave = () => {
    // we want to skip validation on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (customerId !== "" && hashValue !== "") {
      return true;
    } else {
      return false;
    }
  };

  const handleDelete = async () => {
    await deleteCustomerConfig(props.selectedRows);
    handleClose();
    props.loadCustomers(props.dcValue);
    props.onRowSelection(false);
    setCustomerId("");
    setHashValue("");
    setBoxLabelValue("");
    props.onSelectRow([]);
  };

  async function deleteCustomerConfig(body) {
    let customerIds = Array.prototype.map
      .call(body, function (item) {
        return item.data.customerId;
      })
      .join(",");

    const toastMessage = "Customer config removed successfully";
    await HashCustomerService.deleteCustomer(customerIds, props.dcValue)
      .then((response) => {
        toast.success(toastMessage);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }
  const saveCustomer = async (e) => {
    if (customerId === "") {
      setValidSave(false);
    } else {
      e.preventDefault();
      await save(customerId);
      props.loadCustomers(props.dcValue);
      setCustomerId("");
      setHashValue("");
      setBoxLabelValue("");
      setValidSave(false);
      props.onSelectRow([]);
    }
  };

  async function save() {

    let payload = {
      customerId: customerId,
      hash_eligible: hashValue,
      boxLabelType: boxLabelValue.trim(),
      last_modified_by: props.loggedInUser,
      destination_location_code: props.dcValue,
    };
    payload = { customer: payload };
    await HashCustomerService.saveCustomer(payload)
      .then((response) => {
        toast.success(response.data.status);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }

  return (
    <div>
      <NikeDesignSystemProvider>
        <Form id="search-form">
          <Row className="d-flex justify-content-around row py-6">
            <Form.Group as={Col}>
              <TextFieldStyled
                value={customerId}
                type="text"
                id="customer"
                requiredIndicator="*"
                placeholder="Customer"
                name="customerField"
                maxLength={20}
                test-id="customer-test-id"
                label="Customer"
                onChange={(event) => {
                  handleCustomer(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <DropDownStyled
                name="hashSelect"
                id="hashSelect"
                value={hashValue}
                requiredIndicator="*"
                placeholder="HASH Eligible"
                error={false}
                autoComplete="off"
                disabled={false}
                options={[
                  { label: "TRUE", value: "true" },
                  { label: "FALSE", value: "false" },
                ]}
                errorMessage="Error message"
                onChange={(event) => handleHash(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <TextFieldStyled
                  value={boxLabelValue}
                  type="text"
                  id="boxLabel"
                  placeholder="Box Label Type"
                  name="boxLabelField"
                  maxLength={20}
                  label="Box Label Type"
                  onChange={(event) => handleBoxLabel(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to delete the record(s)?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    No
                  </Button>
                  <Button variant="primary" onClick={handleDelete}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={showAlertModal} onHide={handleAlertModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please select a record(s)</Modal.Body>
              </Modal>
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Button
                  type="NikeApp"
                  iconsize="m"
                  test-id="save-button"
                  disabled={!validSave}
                  disabledTip={{
                    message: "Customer & Hash is a Mandatory field for Save",
                    position: "right",
                    size: "medium",
                  }}
                  onClick={(e) => saveCustomer(e)}
                  theme={{
                    primary: validSave ? "#000000" : "rgba(215,250,215,0.62)",
                    secondary: validSave ? "#ffffff" : "rgba(165,165,165,0.62)",
                  }}
                >
                  Save
                </Button>
                <Button
                  iconsize="m"
                  test-id="delete-button"
                  className="me-1 ms-1 float-end text-light bg-dark"
                  onClick={() => {
                    if (props.selectedRows.length === 0) {
                      handleAlertModalShow();
                    } else {
                      handleShow();
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </NikeDesignSystemProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    customers: state.customers,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCustomers: HashCustomerService.loadAllCustomerRecords,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customer);

export { Customer };
