import { combineReducers } from "redux";
import instrcns from "./instructionsReducer";
import customers from "./customersReducer";
import carrierHubs from "./carrierHubReducer";
import launch from "./launchReducer";
import locationManagement from "./locationManagementReducer";
import spurDetailList from "./spurSummaryReducer";
import spurDetailMixedList from "./spurSummaryMixedReducer";
import loadingGroupList from "./loadingGroupReducer";
import spurDetailListTotal from "./spurSummaryTotalReducer";

const rootReducer = combineReducers({
  instrcns,
  customers,
  carrierHubs,
  launch,
  locationManagement,
  spurDetailList,
  spurDetailMixedList,
  loadingGroupList,
  spurDetailListTotal,
});

export default rootReducer;
