import initialState from "./initialState";


export default function spurTotalSummaryReducer(
    state = initialState.spurDetailListTotal,
    action
) {
  switch (action.type) {
    case "GET_SPUR_DETAILS_TOTAL":
      return action.spurDetailListTotal;
    case "GET_SPUR_DETAILS_TOTAL_FAILED":
      return action.spurDetailListTotal;
    default:
      return state;
  }
}