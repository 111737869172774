import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {LaunchValidationService} from "../../services/service.launchValidation";
import Launch from "./Launch";


class LaunchTable extends React.Component {

    render() {
        return (
            <div>
                <Launch
                    loggedInUser={this.props.loggedInUser}
                    dcValue={this.props.dcValue}
                ></Launch>
            </div>
        );
    }
}



const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            importLaunchFile: LaunchValidationService.importLaunchFile,
        },
        dispatch
    );

function mapStateToProps(state) {
    return {
        launch: state.launch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaunchTable);

export { LaunchTable };