import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import React from "react";
import ButtonPanel from "./ButtonPanel";
import { bindActionCreators } from "redux";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import { connect } from "react-redux";

class SearchTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      isSelected: false,
      isHeaderSelected: false,
      api: null,
      columnDefs: [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          field: "selectAll",
          headerName: "",
          width: 50,
        },

        {
          headerName: "Style",
          field: "style_name",
          width: 100,
        },
        { field: "color", width: 100 },
        {
          headerName: "Special Instructions",
          field: "special_instrns",
          wrapText: true,
          autoHeight: true,
          width: 500,
        },
        {
          headerName: "Last updated",
          field: "update_timestamp",
          width: 250,
        },
        { field: "user", width: 300 },
        { field: "id", hide: true },
        { field: "itemIds", hide: true },
      ],
      containerStyle: { width: "100%", height: "50%" },
      gridStyle: { height: "100%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: false,
        filter: true,
      },
    };
  }

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  render() {
    const onGridReady = (params) => {
      this.setState({ api: params.api });
      if (this.props.isSearchMAWM === undefined) {
        this.props.searchFunction(this.props.itemStyle, this.props.itemColor);
      }
    };

    const onRowSelect = (event) => {
      this.setState({ isSelected: event.node.isSelected() });
      this.props.onSelectRow(this.state.api.getSelectedRows());
    };

    return (
      <div style={this.state.containerStyle}>
        <div style={this.state.gridStyle} className="ag-theme-alpine mb-3">
          <AgGridReact
            rowData={this.props.instrcns}
            columnDefs={this.state.columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"multiple"}
            pagination={true}
            enableCellTextSelection={true}
            suppressHorizontalScroll={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            onRowSelected={onRowSelect}
          ></AgGridReact>
        </div>
        <ButtonPanel
          isSelected={this.state.isSelected}
          isSearchMAWM={this.props.isSearchMAWM}
          isSearchLocal={this.props.isSearchLocal}
          selectedRows={this.props.selectedRows}
          itemStyle={this.props.itemStyle}
          itemColor={this.props.itemColor}
          loggedInUser={this.props.loggedInUser}
          onRowSelection={this.onRowSelection}
          onSelectRow={this.props.onSelectRow}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchFunction: SpecialInstructionService.getStyle,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    instrcns: state.instrcns,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTable);

export { SearchTable };
