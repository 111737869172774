/* eslint-disable no-useless-concat */
import React, {useEffect, useState} from "react";
import { Form, Row, Modal } from "react-bootstrap";
import "../component.css";
import "rsuite/dist/rsuite.min.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoadingGroupService } from "../../services/service.loadingGroup";
import {
  NikeDesignSystemProvider,
  ButtonStyled,
  TextStyled, Typeahead, TextFieldStyled,
} from "@nike/nike-design-system-components";

function SpurSearch(props) {
  const {dcValue, onSpurListFetch, onSpurTypeListFetch} = props;
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalClose = () => setShowAlertModal(false);

  useEffect( () => {
    // React advises to declare the async function directly inside useEffect
    async function loadPost() {
       await LoadingGroupService.loadSpurFilters(dcValue)
          .then((response) => {
            onSpurListFetch(response.data.spurs);
            onSpurTypeListFetch(response.data.spurTypes);
          }).catch((response) => {
        console.log("Could not fetch spurs");
            });
    }
    loadPost();
  }, [dcValue, onSpurListFetch, onSpurTypeListFetch]);

  function reset() {
    props.resetSpurFilters();
  }
  function fetchSpurDetailsWithFilters() {
    props.loadSpurDetails(props.dcValue, props.spur, props.spurType, props.status, props.shipmentId);
  }

  return (
    <div className="row mt-1">
      <Form id="spur-search-form" className={" row "}>
        <NikeDesignSystemProvider>
          <TextStyled appearance="title1 text-bold mb-0">Spur Summary</TextStyled>
          <Form.Group as={Row}>
            <Typeahead
                id="spur"
                label="Spur"
                disabled = {true}
                controlledValue={props.spur}
                className="w-50"
                searchableItems={props.spurList}
                showListboxWhenEmpty={true}
                noResultsMessage="Spurs Unavailable"
                onItemSelect={ (selectedValue) => props.setSpur(selectedValue)}
                onInputChange={ (selectedValue) => props.setSpur(selectedValue)}
            />
            <Typeahead
                id="spurType"
                label="Spur Type"
                disabled = {true}
                controlledValue={props.spurType}
                className="w-50"
                searchableItems={props.spurTypeList}
                noResultsMessage="Spur Types Unavailable"
                showListboxWhenEmpty={true}
                onInputChange={ (selectedValue) => props.setSpurType(selectedValue)}
                onItemSelect={ (selectedValue) => props.setSpurType(selectedValue)}
            />
          </Form.Group>
          <Form.Group as={Row}>
            <Typeahead
                id="status"
                label="Spur Status"
                disabled = {true}
                controlledValue={props.status}
                className="w-50 mt-3"
                searchableItems={props.statusList}
                showListboxWhenEmpty={true}
                onInputChange={ (selectedValue) => props.setStatus(selectedValue)}
                onItemSelect={ (selectedValue) => props.setStatus(selectedValue)}
                noResultsMessage="Status Unavailable"/>
            <TextFieldStyled
                type="text"
                placeholder="Shipment"
                maxLength={50}
                id="shipment"
                label="Shipment"
                className="w-50 mt-3"
                onChange={(event) => props.setShipmentId(event.target.value)}
                value={props.shipmentId}
            />
          </Form.Group>
          <Row className="mb-3 ms-0 mt-25">
            <Form.Group as={Row}>
              <ButtonStyled
                appearance="primary"
                type="Button"
                className="mex-prompt-save-button margin-right"
                data-testid="save-button"
                onClick={ () => fetchSpurDetailsWithFilters()}
              >
                {" "}
                Apply
              </ButtonStyled>
              <ButtonStyled
                appearance="primary"
                className="mex-prompt-save-button"
                data-testid="save-button"
                onClick={() => reset()}
                type="Button"
              >
                Reset
              </ButtonStyled>
            </Form.Group>
          </Row>
          <Modal show={showAlertModal} onHide={handleAlertModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a search criteria</Modal.Body>
          </Modal>
        </NikeDesignSystemProvider>
      </Form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    spurFilters: state.spurFilters,
    spurDetailList: state.spurDetailList,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFilters:
      LoadingGroupService.loadSpurFilters,
      loadSpurDetails: LoadingGroupService.loadSpurDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SpurSearch);

export { SpurSearch };
