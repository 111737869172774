import React, { useState } from "react";
import { Button } from "@nike/epic-react-ui";
import { Row, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import {
  NikeDesignSystemProvider,
  ButtonStyled,
  TextStyled,
  TextFieldStyled, DropDownStyled,
} from "@nike/nike-design-system-components";
import { toast } from "react-toastify";
import { LoadingGroupService } from "../../services/service.loadingGroup";

function LoadingGroupButtonPanel(props) {
  const [loadingGroup, setLoadingGroup] = useState("");
  const [reprintType, setReprintType] = useState("");
  const [showReprint, setShowReprint] = useState(false);

  const handleShowReprint = () => {
    setShowReprint(true);
    setReprintType("START");
  }

  const handleCloseReprint = () => {
    setLoadingGroup("");
    setShowReprint(false);
    setReprintType("");
  };

  const handleReprintType = (reprintType) => {
    setReprintType(reprintType);
  }

  const handleConfirmReprint = async () => {
    let payload = {
      loadingGroup: loadingGroup,
      createdBy: props.loggedInUser,
      destLocCode: props.dcValue,
    };
    await LoadingGroupService.rePrintSpur(payload, reprintType)
      .then((response) => {
        toast.success("Loading Group Reprint successfully completed");
        handleCloseReprint();
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
        handleCloseReprint();
      });
  };

  return (
    <div className=" row ">
      <NikeDesignSystemProvider>
        <Form.Group as={Row} className="justify-content-end me-0 mt-1 pe-0">
          <ButtonStyled
            appearance="primary"
            className="mex-prompt-save-button button-wrapper margin-right"
            data-testid="reprint-spur-button"
            type="button"
            onClick={() => handleShowReprint()}
            //disabled={isEnableButton()}
          >
            {"Reprint Spur"}
          </ButtonStyled>
          <ButtonStyled
            appearance="primary"
            className=" button-wrapper "
            data-testid="refresh-button"
            type="button"
            onClick={() => window.location.reload()}
          >
            {"Refresh"}
          </ButtonStyled>

          <Modal show={showReprint} onHide={handleCloseReprint}>
            <Modal.Header closeButton>
              <div className="fw-bold">
                Reprint Loading Group - Spur Start/End
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="ms-0 mt-4 ps-5 w-50">
                  <TextStyled>Loading Group</TextStyled>
                </div>
                <TextFieldStyled
                    name="loading_group"
                    id="loadingGroup"
                    value={loadingGroup}
                    placeholder="Loading Group"
                    label="Loading Group"
                    onChange={(event) => setLoadingGroup(event.target.value)}
                    disabled={false}
                    className="w-50 float-end"
                    ariaLive="polite"
                />
                <div className="ms-0 mt-4 ps-5 w-50">
                  <TextStyled>Label Type</TextStyled>
                </div>
                <DropDownStyled
                    name="label_type"
                    id="labelType"
                    value={reprintType}
                    placeholder="Label Type"
                    error={false}
                    autoComplete="off"
                    disabled={false}
                    options={[
                      { label: "START", value: "START" },
                      { label: "END", value: "END" },
                    ]}
                    className="w-50 float-end"
                    ariaLive="polite"
                    onChange={(event) => handleReprintType(event.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleConfirmReprint}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={handleCloseReprint}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Form.Group>
      </NikeDesignSystemProvider>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    instrcns: state.instrcns,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchStyleFunction: SpecialInstructionService.getStyle,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingGroupButtonPanel);
export { LoadingGroupButtonPanel };
