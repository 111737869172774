import initialState from "./initialState";

export default function instructionsReducer(
  state = initialState.instrcns,
  action
) {
  switch (action.type) {
    case "SEARCH_SUCCESS":
      return action.instrcns;

    default:
      return state;
  }
}
