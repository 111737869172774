import config from "../config/config.js";
import moment from "moment";

const API_INTERFACE_URI = {
  /**
   * Special Instructions service endpoints
   */
  SPCL_INSTRCNS_PATH: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/update/`,
  DEL_SPCL_INSTRCNS_PATH: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/delete/`,
  SEARCH_STYLE_PATH: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/search_style/v1/`,
  SEARCH_INSTRUCTIONS: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/search_instructions/v1/`,
  LOCAL_DB_SPCL_INSTRNS: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/special_instructions/v1/`,
  EXPORT_ALL_PATH: `${config.specialInstrcnApiUrl}/api/node/wmextn/special_instrns/export_all_special_instructions/v1/`,
  EXPORT_ALL_FILE_NAME:
    "special_instructions - All " +
    moment().format("DD-MM-YYYY hh.mm A") +
    ".csv",
  GET_ALL_CUSTOMERS: `${config.customerApi}/api/node/preprocessor/get_all_customers/v1/`,
  GET_CUSTOMER: `${config.customerApi}/api/node/preprocessor/search_customer/v1/`,
  SAVE_CUSTOMER: `${config.customerApi}/api/node/preprocessor/update_customer/v1/`,
  DELETE_CUSTOMER: `${config.customerApi}/api/node/preprocessor/delete_customer/v1`,
  GET_ALL_CARRIER_HUBS: `${config.carrierHubApi}/api/node/preprocessor/get_all_carrierhub/v1/`,
  SAVE_CARRIER_HUB: `${config.carrierHubApi}/api/node/preprocessor/update_carrierhub/v1/`,
  DELETE_CARRIER_HUBS: `${config.carrierHubApi}/api/node/preprocessor/delete_carrierhub/v1`,
  GET_AD_GROUPS: `${config.customerApi}/api/node/preprocessor/get_ad_groups/v1/`,
  LAUNCH_VALIDATION_IMPORT: `${config.customerApi}/node/preprocessor/import_data/{launchType}/v1/`,
  LAUNCH_VALIDATION_EXPORT: `${config.customerApi}/node/preprocessor/export_data/{launchType}/v1/`,
  LOCAL_DB_LOCATION_MGMT: `${config.locationMgmtApi}/api/node/wmextn/locn_mgmt/search/v1/`,
  LOCATION_MGMT_IMPORT: `${config.locationMgmtApi}/api/node/wmextn/locn_mgmt/import/v1/`,
  LOCATION_MGMT_SUBMIT: `${config.locationMgmtApi}/api/node/wmextn/locn_mgmt/submit/v1/`,
  LOCATION_MGMT_EXPORT: `${config.locationMgmtApi}/api/node/wmextn/locn_mgmt/export/v1/`,
  LOADING_GROUP_SPUR_FILTERS: `${config.loadingGroupApi}/api/node/wmextn/loading_group/spur_list/v1/`,
  LOADING_GROUP_SPUR_DETAILS: `${config.loadingGroupApi}/api/node/wmextn/loading_group/spur_details/v1/`,
  LOADING_GROUP_DETAILS: `${config.loadingGroupApi}/api/node/wmextn/loading_group/loading_group_details/v1/`,
  LOADING_GROUP_UPDATE: `${config.loadingGroupApi}/api/node/wmextn/loading_group/update/v1`,
  LOADING_GROUP_REPRINT_SPUR: `${config.loadingGroupApi}/api/node/wmextn/loading_group/reprint_spur/v1/`,
  LOADING_GROUP_FLAG_VALUES: `${config.loadingGroupApi}/api/node/wmextn/loading_group/loading-group-config/v1/`,
};

export default API_INTERFACE_URI;
