
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import ButtonPanel from "./ButtonPanel";
import { bindActionCreators } from "redux";
import { SpecialInstructionService } from "../../services/service.specialInstructions";
import { connect } from "react-redux";

var checkboxSelection = function (params) {
  return params.columnApi.getRowGroupColumns().length === 0;
};

class DefaultTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      isHeaderSelected: false,
      api: null,
      columnDefs: [
        {
          checkboxSelection: checkboxSelection,
          field: "selectAll",
          headerName: "",
          width: 50,
        },

        {
          headerName: "Style",
          field: "style_name",
          width: 100,
        },
        { field: "color", width: 100 },
        {
          headerName: "Special Instructions",
          field: "special_instrns",
          wrapText: true,
          autoHeight: true,
          width: 500,
        },
        {
          headerName: "Last Updated",
          field: "update_timestamp",
          width: 250,
        },
        { field: "user", width: 300 },
      ],
      containerStyle: { width: "100%", height: "50%" },
      gridStyle: { height: "100%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: false,
        filter: true,
      },
    };
    // this.myFunction = this.myFunction.bind(this);
  }

  render() {
    const onGridReady = (params) => {
      this.api = params.api;
      if (this.props.isSearchMAWM === undefined) {
        this.props.localSearchFunction();
      }
    };

    return (
      <div style={this.state.containerStyle}>
        <div style={this.state.gridStyle} className="ag-theme-alpine mb-3">
          <AgGridReact
            rowData={this.props.instrcns}
            columnDefs={this.state.columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"single"}
            enableCellTextSelection={true}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            suppressHorizontalScroll={true}
            onRowSelected={(event) => {
              this.onHandleRowSelection(event);
            }}
          ></AgGridReact>
        </div>
        <ButtonPanel
          isSearchMAWM={this.props.isSearchMAWM}
          isSearchLocal={this.props.isSearchLocal}
          loggedInUser={this.props.loggedInUser}
        />
      </div>
    );
  }

  onHandleRowSelection = (event) => {
    if (event.node.selected) {
      this.props.onChangeItemStyle(event.node.data.style_name);
      this.props.onChangeItemColor(event.node.data.color);
    }
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      localSearchFunction: SpecialInstructionService.loadAllLocalRecords,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    instrcns: state.instrcns,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTable);

export { DefaultTable };
