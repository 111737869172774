/* eslint-disable no-useless-concat */
import React, {useState} from "react";
import { Form, Row, Modal } from "react-bootstrap";
import "../component.css";
import "rsuite/dist/rsuite.min.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
  ButtonStyled,
  TextStyled,
} from "@nike/nike-design-system-components";
import { TextField } from "@nike/eds";
import {LoadingGroupService} from "../../services/service.loadingGroup";
import moment from "moment";

function LoadingGroupSearch(props) {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalClose = () => setShowAlertModal(false);

  function reset() {
    props.onLoadingGroupChange("");
    props.onCarrierChange("");
    props.onCutOffStartChange("");
    props.onCutOffEndChange("");
  }

  function fetchLoadingGroupDetails() {
    props.loadLoadingGroupDetails(props.dcValue, props.loadingGroup, props.carrier, props.cutOffStart, props.cutOffEnd);
  }

  return (
    <div className="row mt-1 ps-0">
      <Form id="search-form" className={" row "} >
        <NikeDesignSystemProvider>
          <TextStyled appearance="title1 text-bold mb-0">Unassigned Loading Groups</TextStyled>
          <Form.Group as={Row}>
            <TextFieldStyled
              type="text"
              placeholder="Loading Group"
              maxLength={40}
              id="loadingGroup"
              label="Loading Group"
              test-id="loading-group"
              className="w-50"
              ariaLive="polite"
              onChange={(event) => props.onLoadingGroupChange(event.target.value)}
              value={props.loadingGroup}
            />
            <TextFieldStyled
              type="text"
              placeholder="Carrier"
              maxLength={40}
              label="Carrier"
              className="w-50 h-25"
              id="carrier"
              test-id="carrier"
              ariaLive="polite"
              onChange={(event) => props.onCarrierChange(event.target.value)}
              value={props.carrier}
            />
          </Form.Group>
          <Form.Group as={Row}>
            <div className="w-50">
            <TextField
              type="datetime-local"
              onChange={(event) => props.onCutOffStartChange(event.target.value)}
              value={props.cutOffStart}
              id="cut-off-start"
              label="Cut-off Start"
              max="2099-12-31T23:59"
            />
            </div>
            <div className="overflow-hidden w-50">
            <TextField
              type="datetime-local"
              onChange={(event) => props.onCutOffEndChange(event.target.value)}
              id="cut-off-end"
              value={props.cutOffEnd}
              label="Cut-off End"
              max="2099-12-31T23:59"
            />
            </div>
          </Form.Group>

          <Row className="mb-3 ms-0 mt-3">
            <Form.Group as={Row}>
              <ButtonStyled
                appearance="primary"
                type="Button"
                className="mex-prompt-save-button margin-right"
                data-testid="save-button"
                onClick={ () => fetchLoadingGroupDetails()}
                //disabled={saveInProgress || disableSave}
              >
                {" "}
                Apply
              </ButtonStyled>
              <ButtonStyled
                appearance="primary"
                type="Button"
                className="mex-prompt-save-button"
                data-testid="save-button"
                onClick={() => reset()}
              >
                Reset
              </ButtonStyled>
            </Form.Group>
          </Row>

          <Modal show={showAlertModal} onHide={handleAlertModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a search criteria</Modal.Body>
          </Modal>
        </NikeDesignSystemProvider>
      </Form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loadingGroupList: state.loadingGroupList,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadLoadingGroupDetails: LoadingGroupService.loadLoadingGroupDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoadingGroupSearch);

export { LoadingGroupSearch };
