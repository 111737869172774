import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GrpActionButton from "./GrpActionButton";
import { LoadingGroupService } from "../../services/service.loadingGroup";
import moment from "moment";

class LoadingGroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingGroup: "",
      isHeaderSelected: false,
      api: null,
      containerStyle: { width: "100%", height: "50%" },
      gridStyle: { height: "113%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        autoHeight: true,
        wrapText: true,
        autoHeaderHeight: true,
        wrapHeaderText: true,
      },
    };
  }

  render() {
    const numberSort = (num1: number, num2: number) => {
      return num1 - num2;
    };

    const dateTimeSort = (dateTime1: string, dateTime2: string) => {
      const timestamp1 = new Date(dateTime1);
      const timestamp2 = new Date(dateTime2);
      return timestamp1 - timestamp2;
    };

    const DateTimeRenderer = (p) => {
      const dateTime = new Date(p.value);
      return (
          moment(dateTime).format("MMMM D, yyyy") +
          "\n" +
          moment(dateTime).format("hh:mm:ss A")
      );
    };

    const columnDefs = [
      {
        checkboxSelection: true,
        headerCheckboxSelection: false,
        field: "selectAll",
        headerName: "",
        width: 10,
      },
      {
        headerName: "Loading Group",
        field: "loading_group_id",
        width: 116,
      },
      {
        headerName: "Carrier",
        field: "carrier_id",
        width: 98,
      },
      {
        headerName: "Service",
        field: "service_level_code",
        width: 101,
        hide: !this.props.displayServiceLevelCode,
      },
      {
        headerName: "Shipment",
        field: "shipment_id",
        autoHeight: true,
        width: 149,
      },
      {
        headerName: "GTP",
        field: "gtp_datetime",
        width: 155,
        hide: !this.props.displayGTP,
      },
      {
        headerName: "Internal Cut-off",
        field: "cutoff",
        width: 155,
        comparator: dateTimeSort,
        cellRenderer: DateTimeRenderer,
        cellStyle: { "white-space": "pre" },
      },
      {
        headerName: "LG Type",
        field: "pltz",
        width: 100,
      },
      {
        headerName: "oLPNs",
        field: "olpns",
        width: 95,
        comparator: numberSort,
      },
      {
        headerName: "Rem oLPNs",
        field: "remaining_olpns",
        width: 130,
        comparator: numberSort,
      },
      {
        headerName: "Final",
        field: "final_plan",
        width: 100,
      },
    ];
    const onGridReady = (params) => {
      this.setState({ api: params.api });
      this.props.loadLoadingGroupDetails(
          this.props.dcValue,
          this.props.loadingGroup,
          this.props.carrier,
          this.props.cutOffStart,
          this.props.cutOffEnd
      );
    };

    const onRowSelect = (event) => {
      this.setState({ isSelected: event.node.isSelected() });
      this.props.onGroupSelectRow(this.state.api.getSelectedRows());
      if (this.state.api.getSelectedRows().length === 1)
        this.setState({
          loadingGroup: this.state.api.getSelectedRows()[0].loading_group_id,
        });
    };

    return (
        <div style={this.state.containerStyle}>
          <div style={this.state.gridStyle} className="ag-theme-alpine mb-table">
            <AgGridReact
                rowData={this.props.loadingGroupList}
                columnDefs={columnDefs}
                autoheight={true}
                wrapText={true}
                defaultColDef={this.state.defaultColDef}
                rowSelection={"multiple"}
                pagination={true}
                enableCellTextSelection={true}
                suppressHorizontalScroll={false}
                alwaysShowHorizontalScroll={true}
                paginationPageSize={10}
                onGridReady={onGridReady}
                onRowSelected={onRowSelect}
            ></AgGridReact>
          </div>
          <div>
            <GrpActionButton
                selectedRows={this.props.groupSelectedRows}
                loadingGroup={this.state.loadingGroup}
                dcValue={this.props.dcValue}
                loggedInUser={this.props.loggedInUser}
                onDeselectRows={() => this.state.api.deselectAll()}
                loadingGroupFilter={this.props.loadingGroup}
                carrier={this.props.carrier}
                cutOffStart={this.props.cutOffStart}
                cutOffEnd={this.props.cutOffEnd}
                spur={this.props.spur}
                spurType={this.props.spurType}
                status={this.props.status}
                shipmentId={this.props.shipmentId}
                setCutOffEnd={this.props.setCutoffEnd}
                setCutOffStart={this.props.setCutoffStart}
            />
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          loadLoadingGroupDetails: LoadingGroupService.loadLoadingGroupDetails,
        },
        dispatch
    );

function mapStateToProps(state) {
  return {
    loadingGroupList: state.loadingGroupList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingGroupTable);

export { LoadingGroupTable };
