import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import CarrierHubTable from "../components/CarrierHub/CarrierHubTable";

export class CarrierHubContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      api: null,
      carrierHub: "",
    };
  }

  onSelectRow = (value) => {
    this.setState({ selectedRows: value });
  };

  render() {
    return (
        <div className="container col-sm-6 col-md-6 col-lg-5 col-xl-3 h-100 m-auto">
          <div className="row mt-3 justify-content-center">
            <div className="col-sm-12">
              <CarrierHubTable
                  carrierHubId={this.state.carrierHub}
                  loggedInUser={this.props.loggedInUser}
                  onSelectRow={this.onSelectRow}
                  selectedRows={this.state.selectedRows}
                  dcValue={this.props.dcValue}
              />
            </div>
          </div>
        </div>
    );
  }
}

export default CarrierHubContainer;
