import React, { useState } from "react";
import { Button } from "@nike/epic-react-ui";
import { Col, Form, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CarrierHubService } from "../../services/service.carrierHubs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  NikeDesignSystemProvider,
  TextFieldStyled,
} from "@nike/nike-design-system-components";

function CarrierButtonPanel(props) {
  const [isValidCriteria, setValidCriteria] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const handleAlertModalShow = () => setShowAlertModal(true);
  const handleAlertModalClose = () => setShowAlertModal(false);
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false);
  const handleDeleteAlertModalClose = () => setShowDeleteAlertModal(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [carrierHubId, setCarrierHubId] = useState("");

  const handleCarrierHub = (carrierHub) => {
    setCarrierHubId(carrierHub);
    if (carrierHubId !== "" || props.carrierHubId !== "")
      setValidCriteria(true);
  };

  const handleDelete = async () => {
    await deleteCarrierHubConfig(props.selectedRows);
    handleClose();
    props.loadCarrierHubs(props.dcValue);
    props.onRowSelection(false);
    setCarrierHubId("");
    props.onSelectRow([]);
  };

  async function deleteCarrierHubConfig(body) {
    let carrierHubId = Array.prototype.map
      .call(body, function (item) {
        return item.data.carrierHubId;
      })
      .join(",");

    const toastMessage = "Carrier + Hub config removed successfully";
    await CarrierHubService.deleteCarrierHubs(carrierHubId, props.dcValue)
      .then((response) => {
        toast.success(toastMessage);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }
  const saveCarrierHub = async (e) => {
    e.preventDefault();
    if (carrierHubId === "") {
      setValidCriteria(false);
    } else {
      await save(carrierHubId);
      props.loadCarrierHubs(props.dcValue);
      setCarrierHubId("");
      setValidCriteria(false);
      props.onSelectRow([]);
    }
  };

  async function save() {
    let payload = {
      carrierHubId: carrierHubId,
      last_modified_by: props.loggedInUser,
      destination_location_code: props.dcValue,
    };
    payload = { carrierHub: payload };
    await CarrierHubService.saveCarrierHub(payload)
      .then((response) => {
        toast.success(response.data.status);
      })
      .catch((response) => {
        if (response !== undefined && response.data !== undefined)
          toast.error(response.data.message);
      });
  }

  return (
    <div className="row d-flex justify-content-center">
      <NikeDesignSystemProvider>
      <Form>
        <div className="d-inline-flex w-100">
          <Form.Group as={Col} className="col-6">
            <TextFieldStyled
                type="text"
                placeholder="CARRIER + HUB"
                requiredIndicator="*"
                test-id="carrierHub"
                value={carrierHubId}
                label="CARRIER + HUB"
                maxLength={20}
                onChange={(event) => handleCarrierHub(event.target.value)}
                ariaLive="polite"
              />
          </Form.Group>

          <div className="col-3 text-center mt-2 py-1">
            <Button
              iconsize="m"
              test-id="save-button"
              onClick={(e) => saveCarrierHub(e)}
              disabled={!isValidCriteria}
              disabledTip={{
                message: "CARRIER + HUB is a Mandatory field",
                position: "right",
                size: "medium",
              }}
              className="w-auto"
              theme={{
                primary: isValidCriteria ? "#000000" : "rgba(215,250,215,0.62)",
                secondary: isValidCriteria
                  ? "#ffffff"
                  : "rgba(165,165,165,0.62)",
              }}
            >
              Save
            </Button>
          </div>
          <Form.Group as={Col} className="col-3 mt-2 py-1">
            <Button
              iconsize="m"
              test-id="delete-button"
              className="float-end text-light bg-dark w-100"
              onClick={() => {
                if (props.selectedRows.length === 0) {
                  handleAlertModalShow();
                } else {
                  handleShow();
                }
              }}
            >
              Delete
            </Button>
          </Form.Group>

          <Modal show={showAlertModal} onHide={handleAlertModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a record(s)</Modal.Body>
          </Modal>

          <Modal
            show={showDeleteAlertModal}
            onHide={handleDeleteAlertModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select a record(s)</Modal.Body>
          </Modal>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete the record(s)?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={handleDelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Form>
    </NikeDesignSystemProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    carrierHubs: state.carrierHubs,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCarrierHubs: CarrierHubService.loadAllCarrierHubRecords,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CarrierButtonPanel);

export { CarrierButtonPanel };
