import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";
import moment from "moment";

function loadAllLocationMgmtRecords(fromDate, toDate, user, destLocCode) {
  let locationMgmtParams = ``;
  let url = API_INTERFACE_URI.LOCAL_DB_LOCATION_MGMT;
  locationMgmtParams = locationMgmtParams.concat(
    `filters=destLocCode(${destLocCode})`
  );
  if (user !== "") {
    locationMgmtParams = locationMgmtParams.concat(
      `&filters=modifiedBy(${user})`
    );
  }

  if (null != toDate && toDate !== "" && null != fromDate && fromDate !== "") {
    const startDate = moment(fromDate).format("MM/DD/YYYY");
    const endDate = moment(toDate).format("MM/DD/YYYY");

    locationMgmtParams = locationMgmtParams.concat(
      `&filters=dateFrom(${startDate})&filters=dateTo(${endDate})`
    );
  }

  if (locationMgmtParams !== undefined) url = url + "?" + locationMgmtParams;
  return baseService.fetchLocationMgmts(url, globalHeaders);
}

function importLocationMgmtFile(user, destLocCode, payload) {
  let locationMgmtParams = ``;
  let url = API_INTERFACE_URI.LOCATION_MGMT_IMPORT;
  locationMgmtParams = locationMgmtParams.concat(
    `filters=destLocCode(${destLocCode})`
  );
  if (user !== "") {
    locationMgmtParams = locationMgmtParams.concat(
      `&filters=modifiedBy(${user})`
    );
  }
  let multipartHeader = { ...globalHeaders };
  multipartHeader["Content-Type"] = "multipart/form-data";

  if (locationMgmtParams !== undefined) url = url + "?" + locationMgmtParams;
  return baseService.post(url, multipartHeader, payload);
}

function sendToMAWM(selectedRows, loggedInUser) {
  selectedRows.forEach((temp) => {
    delete temp.rowCount;
    delete temp.status;
    delete temp.itemCount;
    delete temp.modifiedOn;
    temp.user = loggedInUser;
  });
  return baseService.post(
    API_INTERFACE_URI.LOCATION_MGMT_SUBMIT,
    globalHeaders,
    selectedRows[0]
  );
}

function downloadLocFile(fileUri, destLocCode) {
  let locationMgmtParams = ``;
  let url = API_INTERFACE_URI.LOCATION_MGMT_EXPORT;

  locationMgmtParams = locationMgmtParams.concat(
    `filters=destLocCode(${destLocCode})`
  );
  if (fileUri !== "") {
    locationMgmtParams = locationMgmtParams.concat(
      `&filters=fileName(${fileUri})`
    );
  }

  if (locationMgmtParams !== undefined) url = url + "?" + locationMgmtParams;
  let multipartHeader = { ...globalHeaders };
  multipartHeader["Content-Type"] = "multipart/form-data";
  return baseService.exportAllData(url, multipartHeader);
}

export const LocationMgmtService = {
  loadAllLocationMgmtRecords,
  importLocationMgmtFile,
  sendToMAWM,
  downloadLocFile,
};
