import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function loadAllCustomerRecords(destination_location_code) {
  let destinationCode = `filters=destination_location_code(${destination_location_code})`;
  let url = API_INTERFACE_URI.GET_ALL_CUSTOMERS;
  if (destination_location_code !== undefined)
    url = url + "?" + destinationCode;
  return baseService.listCustomers(url, globalHeaders);
}

function getCustomer(customerId, destination_location_code) {
  let customerParams = `filters=customerId(${customerId})`;
  let url = API_INTERFACE_URI.GET_CUSTOMER;
  if (destination_location_code !== "") {
    customerParams = customerParams.concat(
      `&filters=destination_location_code(${destination_location_code})`
    );
  }
  if (destination_location_code !== undefined) url = url + "?" + customerParams;
  return baseService.fetchCustomer(url, globalHeaders);
}

function saveCustomer(payload) {
  return baseService.post(
    API_INTERFACE_URI.SAVE_CUSTOMER,
    globalHeaders,
    payload
  );
}

function deleteCustomer(customerIds, destination_location_code) {
  let customerParams = `filters=customerId(${customerIds})`;
  let url = API_INTERFACE_URI.DELETE_CUSTOMER;
  if (destination_location_code !== "") {
    customerParams = customerParams.concat(
      `&filters=destination_location_code(${destination_location_code})`
    );
  }
  if (destination_location_code !== undefined) url = url + "?" + customerParams;
  return baseService.delete(url, globalHeaders);
}

export const HashCustomerService = {
  loadAllCustomerRecords,
  getCustomer,
  saveCustomer,
  deleteCustomer,
};
