import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@nike/epic-react-ui";
import { connect } from "react-redux";
import moment from "moment";

const headers = [
  { label: "Style", key: "style_name" },
  { label: "Color", key: "color" },
  { label: "Special Instructions", key: "special_instrns" },
  { label: "Last Updated", key: "update_timestamp" },
  { label: "User", key: "user" },
  { label: "ID", key: "id" },
];

class ExportCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: headers,
      date: moment().format("DD-MM-YYYY hh.mm A"),
      instrcns: [],
    };
  }

  // To check for redux state
  componentWillReceiveProps(nextProps) {
    if (nextProps.instrcns !== this.state.instrcns) {
      this.setState({ instrcns: nextProps.instrcns });
    }
  }

  render() {
    return (
      <CSVLink
        data={this.state.instrcns}
        headers={this.state.headers}
        filename={"special_instructions " + this.state.date + ".csv"}
        target="_blank"
        separator={","}
        test-id="csv-link"
      >
        <Button
          medium="true"
          test-id="export-button"
          type="button"
          disabled={this.state.instrcns.length === 0}
          theme={{
            primary:
              this.state.instrcns.length !== 0
                ? "#000000"
                : "rgba(215,250,215,0.62)",
            secondary:
              this.state.instrcns.length !== 0
                ? "#ffffff"
                : "rgba(165,165,165,0.62)",
          }}
          className="me-4"
        >
          Export
        </Button>
      </CSVLink>
    );
  }
}

const mapStateToProps = (state) => ({
  instrcns: state.instrcns,
});

export default connect(mapStateToProps, null)(ExportCSV);
