import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import Customer from "./Customer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HashCustomerService } from "../../services/service.hashCustomers";

class HashTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      isSelected: false,
      columnDefs: [
        {
          checkboxSelection: true,
          field: "selectAll",
          headerName: "",
          width: 100,
        },

        {
          headerName: "Customer",
          field: "customerId",
          width: 450,
        },
        {
          headerName: "HASH Eligible",
          field: "hash_eligible",
          width: 240,
          valueFormatter: booleanFormatter,
        },
        {
          headerName: "Box Label Type",
          field: "boxLabelType",
          width: 250,
        },
      ],
      containerStyle: { width: "80%", height: "160%" },
      gridStyle: { height: "160%", width: "100%", textAlign: "center" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: false,
        filter: true,
      },
    };

    function booleanFormatter(params) {
      if (params.value != null) {
        return params.value.toString();
      }
      return params.value;
    }

  }

  render() {
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.props.loadCustomers(this.props.dcValue);
    };

    return (
      <div style={this.state.containerStyle}>
        <div style={this.state.gridStyle} className="ag-theme-alpine mb-3">
          <AgGridReact
            rowData={this.props.customers}
            columnDefs={this.state.columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"multiple"}
            enableCellTextSelection={true}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            suppressHorizontalScroll={false}
            onRowSelected={(event) => {
              this.onHandleRowSelection(event);
            }}
          ></AgGridReact>
        </div>
        <Customer
          customerId={this.props.customerId}
          hashEligible={this.props.hashEligible}
          loggedInUser={this.props.loggedInUser}
          selectedRows={this.props.selectedRows}
          isSelected={this.state.isSelected}
          onRowSelection={this.onRowSelection}
          dcValue={this.props.dcValue}
          onSelectRow={this.props.onSelectRow}
        />
      </div>
    );
  }

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  onHandleRowSelection = (event) => {
    this.setState({ isSelected: event.node.selected });
    this.props.onSelectRow(this.gridApi.getSelectedNodes());
    var newBoxVal = "";
    if (event.node.data.boxLabelType !== undefined) {
      newBoxVal = event.node.data.boxLabelType;
    }
    if (event.node.selected) {
      this.props.onCustomerSelect(event.node.data.customerId);
      this.props.onHashSelect(event.node.data.hash_eligible);
      this.props.onBoxLabelSelect(newBoxVal);
    }
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCustomers: HashCustomerService.loadAllCustomerRecords,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    customers: state.customers,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HashTable);

export { HashTable };
