import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import CarrierButtonPanel from "./CarrierButtonPanel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CarrierHubService } from "../../services/service.carrierHubs";

class CarrierHubTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      isSelected: false,
      isHeaderSelected: false,
      columnDefs: [
        {
          checkboxSelection: true,
          field: "selectAll",
          headerName: "",
          width: 120,
        },

        {
          headerName: "CARRIER_HUB",
          field: "carrierHubId",
          wrapText: true,
          autoHeight: true,
          width: 400,
        },
      ],
      containerStyle: { width: "100%", height: "345%" },
      gridStyle: { height: "100%", width: "100%" },
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: false,
        filter: true,
      },
    };
    //this.myFunction = this.myFunction.bind(this);
  }

  render() {
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.props.loadCarrierHubs(this.props.dcValue);
    };

    return (
      <div style={this.state.containerStyle}>
        <div
          style={this.state.gridStyle}
          className="ag-theme-alpine mb-3 m-auto"
        >
          <AgGridReact
            rowData={this.props.carrierHubs}
            columnDefs={this.state.columnDefs}
            autoheight={true}
            wrapText={true}
            defaultColDef={this.state.defaultColDef}
            rowSelection={"multiple"}
            enableCellTextSelection={true}
            pagination={true}
            paginationPageSize={10}
            onGridReady={onGridReady}
            suppressHorizontalScroll={true}
            onRowSelected={(event) => {
              this.onHandleRowSelection(event);
            }}
          ></AgGridReact>
        </div>
        <CarrierButtonPanel
          loggedInUser={this.props.loggedInUser}
          selectedRows={this.props.selectedRows}
          isSelected={this.state.isSelected}
          onRowSelection={this.onRowSelection}
          onSelectRow={this.props.onSelectRow}
          dcValue={this.props.dcValue}
        />
      </div>
    );
  }

  onRowSelection = (value) => {
    this.setState({ isSelected: value });
  };

  onHandleRowSelection = (event) => {
    this.setState({ isSelected: event.node.selected });
    this.props.onSelectRow(this.gridApi.getSelectedNodes());
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadCarrierHubs: CarrierHubService.loadAllCarrierHubRecords,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    carrierHubs: state.carrierHubs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierHubTable);

export { CarrierHubTable };
